import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WhiteBox from '../../atoms/WhiteBox';
import { reservationHistoryData } from '../../../archive/temporaryData';
import PercentageGraph from '../../molecules/PercentageGraph';
import PaymentHistory from '../../organisms/PaymentHistory';
import Button from '../../atoms/Button';
import Table from '../../organisms/Table';
import { ReservationTableTitleList, ReservationTableTitleSummaryList } from '../../../utils/ReservationFormData';
import TableRow from '../../molecules/TableRow';
import { transFormWon } from '../../../utils/numbers';
import RadioGroup from '../../atoms/Radio/RadioGroup';
import Radio from '../../atoms/Radio';
import Pagination from '../../molecules/Pagination';
import MobileDescriptionPop from '../../organisms/MobileDescriptionPop';
import TableDescriptPopContents from '../../molecules/TableDescriptPopContents';
import useCheckPC from '../../../hooks/useCheckPC';
import ReportApi from '../../../lib/ReportApi'
import { useSelector, useDispatch } from 'react-redux';
import { getMasterAccountCustomerNo, isChkResultSuccess, getEnglishDayToKorean, getTotalMinuteToHourMinuteStr, getTotalMinuteToHourMinuteStr2, getCookieInfo, DecryptAES } from '../../../lib/Util'
import { reportContractDateList, reportSummary, reportDrivingList } from '../../../reducers/Report'
import icon_report_date_left from '../../../styles/img/icon_report_date_left.svg'
import icon_report_date_right from '../../../styles/img/icon_report_date_right.svg'
import moment from 'moment';
import { openLoading, closeLoading } from '../../../reducers/loadingRedux';
import ReserveApi from '../../../lib/ReserveApi'
import TableRowCustom from '../../molecules/TableRowCustom';
import TableDescriptPopContentsCustom from '../../molecules/TableDescriptPopContentsCustom';
import PercentageGraphCustom from '../../molecules/PercentageGraphCustom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import qs from 'qs'
import SwalTitleComponent from './Modal/Common/SwalTitleComponent';

// ex) http://localhost:3000/reservation/reservation-history?info=RHJpdmluZ05vPTE2MjkwNyZDdXN0b21lck5vPTEyNjQy
const DECRYPT_FUNC = (search) => {
  let retData = {
    CustomerNo:0,
    DrivingNo:0
  };
  try {
    console.log(search)
    let parseText = qs.parse(search, {ignoreQueryPrefix:true})?.info
    parseText = decodeURIComponent(parseText)
    parseText = atob(parseText)

    
    retData.CustomerNo = qs.parse(parseText, {ignoreQueryPrefix:true})?.CustomerNo
    retData.DrivingNo = qs.parse(parseText, {ignoreQueryPrefix:true})?.DrivingNo
    
  }
  catch(e) {

  }
  return retData
}

const ADMIN_FLAG = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.flag === 'external'
const ADMIN_CUSTOMERNO = qs.parse(document.location.search, { ignoreQueryPrefix: true })?.customerno ?? 0
const GLOBAL_DRIVINGNO = DECRYPT_FUNC(document.location.search).DrivingNo
const GLOBAL_CUSTOMERNO = DECRYPT_FUNC(document.location.search).CustomerNo

/*  암호화 복호화
encodeURIComponent(btoa(JSON.stringify({
    data1: 'helloworld',
    data2 : 'world'
})))

'eyJkYXRhMSI6ImhlbGxvd29ybGQiLCJkYXRhMiI6IndvcmxkIn0%3D'

atob(decodeURIComponent('eyJkYXRhMSI6ImhlbGxvd29ybGQiLCJkYXRhMiI6IndvcmxkIn0%3D'))

'{"data1":"helloworld","data2":"world"}'

JSON.parse(atob(decodeURIComponent('eyJkYXRhMSI6ImhlbGxvd29ybGQiLCJkYXRhMiI6IndvcmxkIn0%3D')))

{data1: 'helloworld', data2: 'world'}

btoa('DrivingNo=162907&CustomerNo=12642')

'RHJpdmluZ05vPTE2MjkwNyZDdXN0b21lck5vPTEyNjQy'

encodeURIComponent(btoa('DrivingNo=162907&CustomerNo=12642'))

'RHJpdmluZ05vPTE2MjkwNyZDdXN0b21lck5vPTEyNjQy'

atob(decodeURIComponent('RHJpdmluZ05vPTE2MjkwNyZDdXN0b21lck5vPTEyNjQy'))

'DrivingNo=162907&CustomerNo=12642'
*/



/**
 * 이용 내역 페이지
 * name : reservation history
 * TODO: 고객 데이터로 값 대체
 * */

const ReservationHistory = () => {
  const [AdminFlag, SetAdminFlag] = useState(false)

  const { isPC } = useCheckPC();
  const [period, setPeriod] = useState({});
  const [rowList, setRowList] = useState([]);
  const [rowListMobile, setRowListMobile] = useState([]);
  const [filterTarget, setFilterTarget] = useState('all');
  const [showDescriptionIndex, setShowDescriptionIndex] = useState(-1)

  /** pagination **/
  // 한 페이지에 보여줄 게시물 개수
  const limit = 10;
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(1);
 
  const [reportList, SetReportList] = useState({
    usageTimeInfo: {
      ContractTypeDay: 0,
      ContractTypeHour: 0,
      RemainTimes: 0
    },
    expence: [{
      StartDate: '2023-05-19T14:00:00',
      ItemCode: '0',
      DrivingNo: 0,
      UseAmount: 0,
      BillingYN: 'N'
    }],
    otherAmount: [{
      StartDate: '2023-05-19T14:00:00',
      Category: '',
      DrivingNo: 0,
      UseAmount: 0,
      BillingYN: 'N'
    }],
    drivingList: [{
      DrivingNo: 0,
      DriverNo: 0,
      DriverName: "",
      DrivingStatus: "use",
      RevStartDt: "2022-09-07T17:00:00",
      RevEndDt: "2022-09-08T01:00:00",
      StartDate: "2022-09-07T16:44:58.323",
      EndDate: "2022-09-07T16:45:08",
      ReserveHourMin: "00:00",
      UseHourMin: "00:00",
      DiffHourMin: "00:00",
      ReserveMin: 0,
      UseMin: 1,
      DiffMin: 0,
      StartAreaFee: 0,
      AreaFee: 0,
      NightFee: 0,
      ExtraTimeFee: 0,
      SelectDriverFee: 0,
      ReserveCancelFee: 0,
      ParkingFee: 0,
      TollgateFee: 0,
      EtcFee: 0,
      UseTime: 0,
      ResultUseTime: 0,
      ExceedAmount: 0,
      UseTimeDeductionAfterAmount: 0,
      RealUseTime: 0,
      RealTimeDeductionAfterAmount: 0,
      ReserveTime: 0,
      RemainTime: 0,
      MinimumTimeApplyFlag: 0,
      DrivingStatusCode: "5",
      CancelType: 0,
      CancelTime: 0,
      CancelAmount: 0,
      BillingYN: "N",
      ExceedTime: 0,
      RevStartAddress: "",
      RevEndAddress: "",
      RevWaypointAddress: "",
      StartAddress: "",
      EndAddress: "",
      WaypointAddress: "",
      MmHourPriceHistory: 0,
      OvercarriedMinute: null,
      Passenger: '',
      PassengerPhoneNo: ''
    }]
  })

  const loginInfo = useSelector(state => state.Login);
  const reportInfo = useSelector(state => state.ReportRedux);
  const dispatch = useDispatch();
  const [mmCustomerInfo, setMmCustomerInfo] = useState({
    Id: 0,
    CustomerNo: 0,
    GroupNumber: 1,
    ContractStartDate: "2022-09-26T00:00:00",
    Month: 0,
    ContractEndDate: "2024-05-25T00:00:00",
    ContractRangeType: 0,
    SuspendDay: 0,
    ContractTypeDay: 0,
    ContractTypeHour: 0,
    OvercarriedMinute: 0,
    ContractOneDayAvaliableHour: 1,
    MmHourPrice: 0,
    ExtrafeeFlagArea: 0,
    ExtrafeeFlagNight: 0,
    LiveFlag: 0,
    ContractRemark: "",
    DeleteYN: "N",
    RegDt: "2022-09-26T14:27:27.74",
    UpdateDt: "2023-09-07T10:58:12.167",
    PrePaymentAmount: 0,
    PostPaymentAmount: 0,
    PrePaymentFlag: 0,
    PostPaymentFlag: 0,
    CustomerName: null,
    EmailAddress: null
  })


  useEffect(() => {
    const tempPage = (page - 1) * limit;
    setOffset(tempPage)
  }, [page])

  const getCustomerNoSwitching = () => {
    return ADMIN_FLAG ? ADMIN_CUSTOMERNO : getMasterAccountCustomerNo(loginInfo.UserPool)
  }

  // 최초 로딩 시의 기본 데이터
  useEffect(() => {
    async function getData() {
      dispatch(openLoading())

      if (ADMIN_FLAG) {
        document.querySelector('.header').style.display = 'none'
        document.querySelector('.manage-reservation').style.paddingTop = '20px'
      }

      if (GLOBAL_DRIVINGNO === 0 || GLOBAL_CUSTOMERNO === 0) {
        alert('잘못된 접근입니다.\n데이터를 표시할 수 없습니다.')
      }
      else {
        
        
        let result2 = await ReportApi.DrivingDetailJasmin(GLOBAL_DRIVINGNO, "0")
        if (isChkResultSuccess(result2)) {
          dispatch(reportSummary(result2.Item))
          // dispatch(reportDrivingList(result2.ItemList))
          SetReportList({
            expence: result2.Item.expence,
            otherAmount: result2.Item.otherAmount,
            drivingList: result2.ItemList.sort((a, b) => {
              const dateA = new Date(a.RevStartDt);
              const dateB = new Date(b.RevStartDt);

              // 내림차순 정렬을 위해 b와 a의 순서를 바꿈
              return dateB - dateA;
            })
          })
        }

      }




      dispatch(closeLoading())

    }
    getData()
  }, [])



  const filterList = [
    {
      value: 'all',
      title: '전체',
    },
    {
      value: 'pending',
      title: '이용예정'
    },
    {
      value: 'completed',
      title: '이용완료'
    },
    {
      value: 'canceled',
      title: '취소완료'
    }
  ]


  const convertContractRanges = (contracts) => {
    const years = {};
    contracts.forEach(contract => {
      const year = contract.StartDate.substring(0, 4);
      if (!years[year]) {
        years[year] = [];
      }
      years[year].push({
        StartDate: contract.StartDate,
        EndDate: contract.EndDate
      });
    });

    for (const year in years) {
      years[year].sort((a, b) => a.StartDate.localeCompare(b.StartDate));
    }

    const converted = Object.keys(years).sort().map(year => ({
      year: year,
      dateList: years[year]
    }));

    return converted;
  }



  //console.log();




  // 이용예정, 이요완료, 취소를 눌렀을 때 실행
  // 분석하느라 시간 좀 소요될 것 같음..  이부분은 패스?
  useEffect(() => {
    setPeriod(reservationHistoryData.period)
    // const history = reservationHistoryData.history
    const emptyRow = '-'
    const tmpDrivingStatus = (status) => {
      let retData = ''
      switch (status) {
        case 'reserve':
          retData = 'pending'
          break;
        case 'use':
          retData = 'completed'
          break;
        case 'cancel':
          retData = 'canceled'
          break;
        default:
          retData = ''
          break;
      }
      return retData
    }


    //getEnglishDayToKorean
    const tmpDateTime = (Driving, type = 'pc') => {
      let hourMinuteStr = ''
      if (Driving.DrivingStatus === 'use') {
        hourMinuteStr = getTotalMinuteToHourMinuteStr(moment(Driving.EndDate).diff(moment(Driving.StartDate), 'minutes'))
      }
      else {
        hourMinuteStr = getTotalMinuteToHourMinuteStr(moment(Driving.RevEndDt).diff(moment(Driving.RevStartDt), 'minutes'))
      }

      if (type === 'pc') {
        return (
          Driving.DrivingStatus === 'use' ?
            `${moment(Driving.StartDate).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.StartDate).format('ddd'))})<br/> ${moment(Driving.StartDate).format('HH:mm')}~${moment(Driving.EndDate).format('HH:mm')}<br/>(${hourMinuteStr})` :
            `${moment(Driving.RevStartDt).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.RevStartDt).format('ddd'))})<br/> ${moment(Driving.RevStartDt).format('HH:mm')}~${moment(Driving.RevEndDt).format('HH:mm')}<br/>(${hourMinuteStr})`
        )
      }

      return (
        Driving.DrivingStatus === 'use' ?
          `${moment(Driving.StartDate).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.StartDate).format('ddd'))}) ${moment(Driving.StartDate).format('HH:mm')}~${moment(Driving.EndDate).format('HH:mm')}` :
          `${moment(Driving.RevStartDt).format('MM/DD')}(${getEnglishDayToKorean(moment(Driving.RevStartDt).format('ddd'))}) ${moment(Driving.RevStartDt).format('HH:mm')}~${moment(Driving.RevEndDt).format('HH:mm')}`
      )

    }

    const tmpDateTime2 = (Driving, type = 'pc') => {
      let hourMinuteStr = ''
      if (Driving.DrivingStatus === 'use') {
        hourMinuteStr = getTotalMinuteToHourMinuteStr2(moment(Driving.EndDate).diff(moment(Driving.StartDate), 'minutes'))
      }
      else {
        hourMinuteStr = getTotalMinuteToHourMinuteStr2(moment(Driving.RevEndDt).diff(moment(Driving.RevStartDt), 'minutes'))
      }
      return hourMinuteStr

    }

    const tmpItemOne = (Driving) => {
      return {
        type: Driving.ExceedTime > 1 ? (Driving.DrivingStatus === 'reserve' ? '초과예약' : '초과이용') : '기본이용',
        usedTime: Driving.ExceedTime > 1 ? `${Math.floor(Driving.ExceedTime / 60)}시간 ${Driving.ExceedTime % 60}분` : '',
        resultUsedTime: `${Math.floor(Driving.ResultUseTime / 60)}시간 ${Driving.ResultUseTime % 60}분`,
        additionalFee: Driving.ExceedAmount
      }
    }

    const tmpEtcAmount = (Driving, Expenses) => {
      let tmpArr = [];

      const getExpenceTypeToStr = (type) => {
        let retData = '-';
        switch (type) {
          case '1':
            retData = '주차비'
            break;
          case '2':
            retData = '통행료'
            break;
          case '3':
            retData = '교통비'
            break;
          case '4':
            retData = '기타'
            break;
          default:
            break;
        }
        return retData;
      }

      const getExpenceArrIdx = (expenceArr, drivingno) => {
        let retData = [];
        for (let i in expenceArr) {
          if (expenceArr[i].DrivingNo === drivingno) {
            retData.push(i);
          }
        }
        return retData;
      }


      let areaFeeSum = 0;

      // 할증 관련 처리
      if (Driving.StartAreaFee >= 1) {
        areaFeeSum += Driving.StartAreaFee
      }
      if (Driving.AreaFee >= 1) {
        areaFeeSum += Driving.AreaFee
      }
      if (Driving.NightFee >= 1) {
        areaFeeSum += Driving.NightFee

      }
      if (areaFeeSum >= 1) {
        tmpArr.push({
          type: '할증',
          title: '',
          amount: areaFeeSum,
          additionalFee: areaFeeSum
        })
      }


      // 결제대행 처리
      let tmpExpenseArr = getExpenceArrIdx(Expenses, Driving.DrivingNo)

      if (tmpExpenseArr.length >= 1) {
        let sum = 0;
        let sum2 = 0;
        for (let i in tmpExpenseArr) {
          sum += Expenses[tmpExpenseArr[i]]?.UseAmount
          sum2 += Expenses[tmpExpenseArr[i]]?.UseAmount + (Expenses[tmpExpenseArr[i]]?.UseAmount * 0.2)
        }

        tmpArr.push({
          type: '결제대행',
          title: '',
          amount: sum,
          additionalFee: sum2
        })

      }

      return tmpArr

    }


    const tmpOtherAmount = (Driving, otherAmountArr) => {
      let tmpArr = []
      let tmpExpenseArr = getExpenceArrIdx(otherAmountArr, Driving.DrivingNo)
      if (tmpExpenseArr.length >= 1) {
        let sum = reportList.otherAmount.map((a) => a.UseAmount).reduce((a, b) => a + b);
        tmpArr.push({
          type: '기타금액',
          title: '',
          amount: sum,
          additionalFee: sum
        })
      }
      return tmpArr
    }

    const tmpSelctDriverFee = (Driving) => {
      let tmpArr = []
      if (Driving.SelectDriverFee >= 1) {
        tmpArr.push({
          type: '지명비',
          title: '',
          amount: Driving.SelectDriverFee,
          additionalFee: Driving.SelectDriverFee
        })
      }

      return tmpArr
    }






    let tmpHistory = []
    for (let i in reportList.drivingList) {
      tmpHistory.push({
        drivingno: reportList.drivingList[i].DrivingNo,
        status: tmpDrivingStatus(reportList.drivingList[i].DrivingStatus),
        usedDate: tmpDateTime(reportList.drivingList[i]),
        usedDateMobile: tmpDateTime(reportList.drivingList[i], 'mobile'),
        usedDateMobileHourMinute: tmpDateTime2(reportList.drivingList[i], 'mobile'),
        items: [
          {
            ...tmpItemOne(reportList.drivingList[i])
          },
          ...tmpEtcAmount(reportList.drivingList[i], reportList.expence),
          ...tmpOtherAmount(reportList.drivingList[i], reportList.otherAmount),
          ...tmpSelctDriverFee(reportList.drivingList[i])
        ],
        diffFlag: (reportList.drivingList[i].ResultUseTime === reportList.drivingList[i].UseMin)
      })
    }
    const history = tmpHistory


    let condition;
    switch (filterTarget) {
      case 'pending':
        condition = historyItem => historyItem.status === 'pending';
        break;
      case 'completed':
        condition = historyItem => historyItem.status === 'completed';
        break;
      case 'canceled':
        condition = historyItem => historyItem.status === 'canceled';
        break;
      default:
        condition = () => true;
    }


    /*
    공통
    BillingYn이 N일 경우 : 집계중
    BillingYn이 Y일 경우 : 금액노출

    * 구분
    DrivingStatus가 reserve : 이용예정
    DrivingStatus가 use : 이용예정
    DrivingStatus가 cancel : 취소

    * 날짜
    1. DrivingStatus가 use일 경우
    StartDate, EndDate로 표시
    1. DrivingStatus가 reserve, cancel일 경우
    RevStartDt, RevEndDt로 표시

    * 항목
     - 상단
       기본 이용
          Exceedtime이 0일 경우는 "기본이용" 표현
       추가 이용
          Exceedtime이 1 이상일 경우는 "추가이용" 표현
          Exceedtime(minute)를 "시간 분" 형태로 표현
    
    * 반영시간
        ResultUseTime을 "시간 분 " 형태로 표현

    * 후불 청구액
         - 상단
             ExceedAmount가 1 이상이면 금액 표시
          - 하단
             ExceedAmount가 1 이상이면 금액 표시
    */








    const tempRowList =
      history.filter(condition).map((historyItem) => {  // filter에 condition func를 넣어서 필터링 하고, 그 결과를 map처리
        let statusText;
        let status;
        switch (historyItem.status) {
          case 'completed':
            statusText = '이용완료'
            break
          case 'pending':
            statusText = '이용예정'
            break
          case 'canceled':
            statusText = '취소완료'
            break
        }
        let rowTypeHtml = ``;
        let additionalFeeHtml = ``;
        let cancelBtnHtml = ``;
        let detailBtnHtml = ``;

        historyItem.items.map((item, index) => {
          const title = item.type
          let summary;
          switch (item.type) {
            case '기본이용':
              summary = ' - '
              break
            case '할증':
            case '기타금액':
            case '지명비':
              summary = item.title
              break
            case '초과이용':
            case '초과예약':
              summary = item.usedTime
              break
            case '결제대행':
              summary = item.title + ' ' + transFormWon(item.amount)
              break
          }
          status = `
                    <span class='status ${historyItem.status} fit-content'>${statusText}</span>  
                    `

          let rowType = ''
          if (title === '기본이용' || title === '할증' || title === '기타금액' || title === '지명비' || title === '결제대행') {
            rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
          </span>`
          }
          else {
            rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
            <span>/</span>
            <span>${summary}</span>
          </span>`
          }


          let additionalFee = `<span key=${index} class='item-set w-full'>
                                <span>${transFormWon(item.additionalFee)}</span>
                              </span>`



          rowTypeHtml += rowType
          additionalFeeHtml += additionalFee

        })

        let cancelBtn = `cancel_${historyItem.drivingno}`
        cancelBtnHtml += historyItem.status === 'pending' ? cancelBtn : emptyRow

        let detailBtn = `detail_${historyItem.drivingno}`
        detailBtnHtml += detailBtn


        let toolTipVisible = ''
        if (!historyItem.diffFlag && historyItem.status === 'completed') {
          toolTipVisible = `
          <br/>
          <div class="tooltip-container">
            <img src="../assets/icon_question.svg" alt="Your Image Description" style='padding-top:5px; width:20px' />
            <div class="tooltip-text">최소이용시간으로 차감됩니다.</div>
          </div>`
        }



        return (
          {
            all: [
              status,
              historyItem.usedDate,
              historyItem.items.filter((item) =>
                item.type === '기본이용' || item.type === '초과이용' || item.type === '초과예약').map((item) => (
                  item.resultUsedTime + toolTipVisible
                )),
              rowTypeHtml,

              additionalFeeHtml,
              cancelBtnHtml,
              detailBtnHtml
            ],
            summary: [
              status,
              historyItem.usedDate,
            ]
          }
        )
      })

    const tempRowListMobile =
      history.filter(condition).map((historyItem) => {  // filter에 condition func를 넣어서 필터링 하고, 그 결과를 map처리
        let statusText;
        let status;
        switch (historyItem.status) {
          case 'completed':
            statusText = '이용완료'
            break
          case 'pending':
            statusText = '이용예정'
            break
          case 'canceled':
            statusText = '취소완료'
            break
        }
        let rowTypeHtml = ``;
        let additionalFeeHtml = ``;
        let cancelBtnHtml = ``;
        let PostPaymentArr = [];
        let detailBtnHtml = ''

        historyItem.items.map((item, index) => {
          const title = item.type
          let summary;
          let tmpObj = {
            title: '',
            amountStr: '',
          }

          switch (item.type) {
            case '기본이용':
              tmpObj.title = `${item.type}`
              break
            case '할증':
            case '기타금액':
            case '지명비':
              tmpObj.title = `${item.type}`
              break
            case '초과이용':
            case '초과예약':
              tmpObj.title = `${item.type} / ${item.usedTime}`
              break
            case '결제대행':
              tmpObj.title = `${item.type}`
              // summary = item.title + ' ' + transFormWon(item.amount)
              break
          }
          tmpObj.amountStr = transFormWon(item.additionalFee)
          PostPaymentArr.push(tmpObj)
          status = `<span class='status ${historyItem.status} fit-content'>${statusText}</span>`





          let rowType = ''
          if (title === '기본이용' || title === '할증' || title === '기타금액' || title === '지명비' || title === '결제대행') {
            rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
          </span>`
          }
          else {
            rowType = ` <span key=${index} class='item-set w-full'>
            <span>${title}</span>
            <span>/</span>
            <span>${summary}</span>
          </span>`
          }


          let additionalFee = `<span key=${index} class='item-set w-full'>
                                <span>${transFormWon(item.additionalFee)}</span>
                              </span>`



          rowTypeHtml += rowType
          additionalFeeHtml += additionalFee

        })

        let cancelBtn = `cancel_${historyItem.drivingno}`
        cancelBtnHtml += historyItem.status === 'pending' ? cancelBtn : emptyRow

        let detailBtn = `detail_${historyItem.drivingno}`
        detailBtnHtml += detailBtn


        let toolTipVisible = ''
        if (!historyItem.diffFlag && historyItem.status === 'completed') {
          toolTipVisible = `
          <div class="tooltip-container">
            <img src="../assets/icon_question.svg" alt="Your Image Description" style=' width:15px' />&nbsp;&nbsp;
            <div class="tooltip-text">최소이용시간으로 차감됩니다.</div>
          </div>`
        }



        return (
          {
            all: [
              status,
              historyItem.usedDateMobile,
              historyItem.items.filter((item) =>
                item.type === '기본이용' || item.type === '초과이용' || item.type === '초과예약').map((item) => (
                  toolTipVisible + item.resultUsedTime
                )),
              rowTypeHtml,

              additionalFeeHtml,
              cancelBtnHtml,
              detailBtnHtml,
              PostPaymentArr,
              historyItem.usedDateMobileHourMinute
            ],
            summary: [
              status,
              historyItem.usedDate,
            ]
          }
        )
      })

    setRowList(tempRowList)
    setRowListMobile(tempRowListMobile)
    setPage(1);
  }, [reservationHistoryData, filterTarget, reportList])





  const getDrivingStatusToStr = (DrivingStatus) => {
    let retData = ''
    switch (DrivingStatus) {
      case 'reserve':
        retData = '이용예정'
        break;
      case 'use':
        retData = '이용완료'
        break;
      case 'cancel':
        retData = '취소완료'
        break;
      default:
        retData = '알수없음'
        break;

    }
    return retData
  }

  const getAddressSummary = (Address) => {
    let retData = ''

    try {
      let tmpArr = Address.split(' ')

      if (tmpArr?.length >= 3) {
        retData += `${tmpArr[0]} ${tmpArr[1]} ${tmpArr[2]}`
      }
      else {
        retData = Address
      }
    }
    catch(e) {

    }
    

    return retData
  }

  const getStartEndDate = (StartDt, EndDt) => {
    return `${moment(StartDt).format('HH:mm')} ~ ${moment(EndDt).format('HH:mm')}`
  }

  const getSumExpence = (expenceArr, DrivingNo) => {

    let retData = 0;
    let tmpArr = [];
    for (let i in expenceArr) {
      if (expenceArr[i].DrivingNo === DrivingNo) {
        tmpArr.push(i);
      }
    }

    for (let s in tmpArr) {
      retData += expenceArr[tmpArr[s]].UseAmount
    }
    return retData;

  }

  const numberWithCommas = (x) => {

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }

  const Commission20 = (amount) => {
    return amount * 0.2
  }

  const getExpenceArrIdx = (expenceArr, drivingno) => {
    let retData = [];
    for (let i in expenceArr) {
      if (expenceArr[i].DrivingNo === drivingno) {
        retData.push(i);
      }
    }
    return retData;
  }

  const getExpenceTypeToStr = (type) => {
    let retData = '-';
    switch (type) {
      case '1':
        retData = '주차비'
        break;
      case '2':
        retData = '통행료'
        break;
      case '3':
        retData = '교통비'
        break;
      case '4':
        retData = '기타'
        break;
      default:
        break;
    }
    return retData;
  }


  /*
  * DrivingStatus reserve, use, cancel 한국어 변경필요
  DrivingStatus가 reserve일 경우 
  RevStartDt, RevEndDt가 표시
  RevStartAddress, RevEndAddress가 표시

  DrivingStatus가 use일 경우 
    DrivingStatusCode가 4일 경우 
      RevStartDt, RevEndDt가 표시
      RevStartAddress, RevEndAddress가 표시

    DrivingStatusCode가 5일 경우 
      RevStartDt, RevEndDt가 표시
      StartDate, EndDate가 표시
      StartAddress, EndAddress가 표시
  */




  const showModalDetail = async (reportResult, expenseResult, otherAmountResult) => {
    //console.log(reportResult.SelectDriverFee)
    const mySwal = withReactContent(Swal)
    mySwal.fire({
      title: (
        <SwalTitleComponent
          title={'운행건 상세정보'}
          cancelBtn={mySwal.clickCancel} />
      ),
      html: (
        <>
          <div className='title-reportdetail'>
            운행건
          </div>
          <div className='content'>
            <div className='content-sub-container'>
              <div className='component'>
                <div>상태</div>
                <div>{getDrivingStatusToStr(reportResult['DrivingStatus'])}</div>
              </div>
              <div className='component'>
                <div>일자</div>
                <div>{moment(reportResult['RevStartDt']).format('yyyy-MM-DD')}</div>
              </div>
              <div className='component'>
                <div>예약시간</div>
                <div>{getStartEndDate(reportResult['RevStartDt'], reportResult['RevEndDt'])}</div>
              </div>

              {(reportResult['DrivingStatus'] === 'use') && (
                <div className='component'>
                  <div>실 이용시간</div>
                  <div>{getStartEndDate(reportResult['StartDate'], reportResult['EndDate'])}</div>
                </div>
              )}

              {((reportResult['DrivingStatus'] === 'reserve') || (reportResult['DrivingStatus'] === 'cancel')) && (
                <>
                  <div className='component'>
                    <div>예약 출발지</div>
                    <div>{getAddressSummary(reportResult['RevStartAddress'])}</div>
                  </div>
                  <div className='component'>
                    <div>예약 종료지</div>
                    <div>{getAddressSummary(reportResult['RevEndAddress'])}</div>
                  </div>
                </>
              )}

              {(reportResult['DrivingStatus'] === 'use') && (
                <>
                  <div className='component'>
                    <div>실 출발지</div>
                    <div>{getAddressSummary(reportResult['StartAddress'])}</div>
                  </div>
                  <div className='component'>
                    <div>실 종료지</div>
                    <div>{getAddressSummary(reportResult['EndAddress'])}</div>
                  </div>
                </>
              )}


            </div>
          </div>



          {reportResult.DrivingStatus !== 'cancel' &&
            <>
              {((reportResult.ExceedAmount >= 1) || (reportResult.SelectDriverFee >= 1) || (getSumExpence(expenseResult, reportResult.DrivingNo) >= 1) || (reportResult.StartAreaFee >= 1 || reportResult.AreaFee >= 1 || reportResult.NightFee >= 1)) ?
                <>
                  <div className='title-reportdetail'>
                    청구 비용 {reportResult.BillingYN === 'N' ? '(예정)' : '(확정)'}
                  </div>
                  <div className='content'>
                    <div className='content-sub-container'>
                      <>
                        {((reportResult.ExceedAmount >= 1) || (reportResult.SelectDriverFee >= 1) || (getSumExpence(expenseResult, reportResult.DrivingNo) >= 1) || (reportResult.StartAreaFee >= 1 || reportResult.AreaFee >= 1 || reportResult.NightFee >= 1)) ? (
                          <>
                            {(reportResult.ExceedAmount >= 1) ? (
                              <>
                                <div className="component bold">
                                  <div className=""><span>초과차감정보</span></div>
                                  <div className=""><span></span></div>
                                </div>
                                <div className="component">
                                  <div className="indent"><span>{reportResult.DrivingStatus !== 'use' ? '초과예약시간' : '초과이용시간'}</span></div>
                                  <div className=""><span>{getTotalMinuteToHourMinuteStr(reportResult.ExceedTime)}</span></div>
                                </div>
                                <div className="component">
                                  <div className="indent"><span>시간당단가</span></div>
                                  <div className=""><span>{numberWithCommas(reportResult.MmHourPriceHistory + '')}원</span></div>
                                </div>

                                <div className="component">
                                  <div className="indent"><span>{reportResult.DrivingStatus !== 'use' ? '초과예약' : '초과이용'}</span></div>
                                  <div className=""><span>{numberWithCommas(reportResult.ExceedAmount + '')}원</span></div>
                                </div>
                              </>
                            ) : null}

                            <>
                              {reportResult.SelectDriverFee >= 1 && (
                                <>
                                  <div className="component bold">
                                    <div className=""><span>지명비</span></div>
                                    <div className=""><span>{numberWithCommas((reportResult.SelectDriverFee))}원</span></div>
                                  </div>
                                </>
                              )}
                            </>

                            <>
                              {(reportResult.StartAreaFee >= 1 || reportResult.AreaFee >= 1 || reportResult.NightFee >= 1) && (
                                <>
                                  <div className="component bold">
                                    <div className=""><span>할증</span></div>
                                    <div className=""><span>{numberWithCommas((reportResult.StartAreaFee + reportResult.AreaFee + reportResult.NightFee) + '')}원</span></div>
                                  </div>

                                  {reportResult.StartAreaFee !== 0 || reportResult.AreaFee !== 0 ? (
                                    <div className="component">
                                      <div className="indent"><span>지역할증</span></div>
                                      <div className=""><span>{numberWithCommas((reportResult.StartAreaFee + reportResult.AreaFee) + '')}원</span></div>
                                    </div>
                                  ) : null}

                                  {reportResult.NightFee !== 0 ? (
                                    <div className="component">
                                      <div className="indent"><span>심야할증</span></div>
                                      <div className=""><span>{numberWithCommas(reportResult.NightFee + '')}원</span></div>
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>

                            {/* 결제대행 (실비) */}
                            {(getSumExpence(expenseResult, reportResult.DrivingNo) >= 1) ? (
                              <>
                                <div className="component bold">
                                  <div className=""><span>결제대행</span></div>
                                  <div className=""><span>{numberWithCommas(getSumExpence(expenseResult, reportResult.DrivingNo) + Commission20(getSumExpence(expenseResult, reportResult.DrivingNo)) + '')}원</span></div>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {getExpenceArrIdx(expenseResult, reportResult.DrivingNo).length >= 1 ? (
                          <>
                            {/* {tmpHeaderComp('결제대행내역')} */}
                            {getExpenceArrIdx(expenseResult, reportResult.DrivingNo).map((tmpdata) => {
                              return (<>
                                <div className="component">
                                  <div className="indent"><span>{getExpenceTypeToStr(expenseResult[tmpdata].ItemCode)}</span></div>
                                  <div className=""><span>{numberWithCommas(expenseResult[tmpdata].UseAmount)}원</span></div>
                                </div>
                              </>)
                            })}
                            <div className="component">
                              <div className="indent"><span>대행수수료</span></div>
                              <div className=""><span>{numberWithCommas(Commission20(getSumExpence(expenseResult, reportResult.DrivingNo))) + ''}원</span></div>
                            </div>

                          </>) : null}

                        {getExpenceArrIdx(otherAmountResult, reportResult.DrivingNo).length >= 1 ? (
                          <>
                            <div className="component bold">
                              <div className=""><span>기타금액</span></div>
                              <div className=""><span>{numberWithCommas((reportResult.BillingOtherAmount))}원</span></div>
                            </div>
                            {/* {tmpHeaderComp('기타금액')} */}
                            {getExpenceArrIdx(otherAmountResult, reportResult.DrivingNo).map((tmpdata) => {
                              return (<>
                                <div className="component">
                                  <div className="indent"><span>기타금액 ({otherAmountResult[tmpdata].Category})</span></div>
                                  <div className=""><span>{numberWithCommas(otherAmountResult[tmpdata].UseAmount)}원</span></div>
                                </div>
                              </>)
                            })}


                          </>) : null}
                      </>

                    </div>
                  </div>
                </> : <></>
              }
            </>
          }


        </>

      ),
      preConfirm: () => {
        // alert('showDetail');
      },
      confirmButtonText: '확인',
      confirmButtonColor: '#1eafd2',

      // cancelButtonText:'취소',
      // showCancelButton:true,
      // cancelButtonColor: '#f6f6f6',

    })
  }

  const getPartnerLoginedInfo = () => {
    let cookieInfo = getCookieInfo()

    return {
      User: JSON.parse(DecryptAES(cookieInfo?.lgi)),
      UserPool: JSON.parse(DecryptAES(cookieInfo?.lgupi))
    }
  }



  const onClickSeeDetailHandler = (index) => {
    let drivingNo = index.split('_')[1]

    // retType : object   (항상존재)
    let reportResult = reportList.drivingList.find((data) => (data.DrivingNo === Number(drivingNo)))
    // retType : array    (없을 수 있음.)
    let expenseResult = reportList.expence.filter((data) => data.DrivingNo === Number(drivingNo))
    // retType : array    (없을 수 있음.)
    let otherAmountResult = reportList.otherAmount.filter((data) => data.DrivingNo === Number(drivingNo))

    // console.log(reportResult)
    // console.log(expenseResult)
    showModalDetail(reportResult, expenseResult, otherAmountResult)

    // alert(index)
  }

  const onClickContractDateList = () => {
    // console.log(convertContractRanges(reportInfo.ContractDateList))


  }

  // 취소시
  const onClickCancelHandler = async (id) => {

    if (ADMIN_FLAG) {
      alert('이 상태에서는 취소가 불가능합니다.')
      return;
    }

    let drivingNo = id.split('_')[1]
    dispatch(openLoading())
    let result = await ReserveApi.CanCancelDriving(drivingNo)
    if (isChkResultSuccess(result)) {
      if (result.Item.CanCancel) {
        if (window.confirm('예약 취소를 진행하시겠습니까?')) {

          let result2 = await ReserveApi.CancelDrivingReservation(GLOBAL_CUSTOMERNO, drivingNo, 1, GLOBAL_CUSTOMERNO)
          if (isChkResultSuccess(result2)) {
            alert('취소 완료 되었습니다.')
            document.location.reload()
          }
          else {
            alert(result2.Message)
          }
        }
      }
      else {
        alert(result.Message)
      }

    }
    else {
      alert(result.Message)
    }
    dispatch(closeLoading())
  }

  if (GLOBAL_DRIVINGNO === 0 || GLOBAL_CUSTOMERNO === 0) {
    return (
    <>
      <div>잘못된 링크입니다.</div>
      <div>링크를 확인해주세요.</div>
    </>
    )  
  }

  return (
    <>
      <div className={classNames('reservation-history table')}>

        {isPC ? (
          <WhiteBox>
            <div className={classNames('reservation-history-table')}>

              {/** table title **/}
              <div className={'table-title'}>
                <div className={classNames('table-title-left')}>
                  <span className={classNames('title-mid')}>예약 내역</span>
                  <RadioGroup
                    legend={'filter'}
                    selected={filterTarget}
                    classes={'filter-list radio-list'}
                  >
                    {filterList.map((target) => {
                      return (
                        <span className={classNames('filter-item')} key={target.value}>
                          <Radio
                            key={target.value}
                            value={target.value}
                            onChange={e => {
                              setFilterTarget(target.value)
                            }}
                            label={target.title}
                          />
                        </span>
                      )
                    })}
                  </RadioGroup>
                </div>

              </div>

              {/** table **/}
              <Table titleList={isPC ? ReservationTableTitleList : ReservationTableTitleSummaryList}>
                {rowList.slice(offset, offset + limit).map((row, index) => {
                  return (
                    <TableRowCustom
                      key={index}
                      row={isPC ? row.all : row.summary}
                      seeDetail={!isPC && true}
                      onClickSeeDetail={onClickSeeDetailHandler}
                      onClickCancel={onClickCancelHandler}
                    />
                  )
                })}
              </Table>

              {/** table pagination **/}
              <Pagination
                page={page}
                setPage={setPage}
                totalPosts={rowList.length}
                limit={limit} />
            </div>
          </WhiteBox>
        ) : (
          <div className={classNames('reservation-history-table')}>
            <WhiteBox>
              {/** table title **/}
              <div className={'table-title'}>
                <div className={classNames('table-title-left')}>
                  <span className={classNames('title-mid')}>예약 내역</span>
                </div>
              </div>
              {rowListMobile.slice(offset, offset + limit).map((row, index) => {
                return (
                  <>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>


                      <div className={classNames('pending-payment-item')}>
                        <div className={classNames('title-set')} style={{ gap: '5px' }}>
                          <div dangerouslySetInnerHTML={{ __html: row.all[0] }} />
                        </div>

                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>예약일시</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: row.all[1] }} />
                            </span>
                          </span>
                        </div>
                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>예약시간</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: row.all[8] }} />
                            </span>
                          </span>
                        </div>

                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>예약 출발지</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }}>
                                {getAddressSummary(reportList.drivingList[0]['RevStartAddress'])}
                              </div>
                            </span>
                          </span>
                        </div>
                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>예약 종료지</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }}>
                                {getAddressSummary(reportList.drivingList[0]['RevEndAddress'])}
                              </div>
                            </span>
                          </span>
                        </div>

                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>탑승자명</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }}>
                                {getAddressSummary(reportList.drivingList[0]['Passenger'])}
                              </div>
                            </span>
                          </span>
                        </div>
                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <span className={classNames('txt-regular')}>전화번호</span>
                          <span className={'amount-set'}>
                            <span className={classNames('txt-regular')}>
                              <div className={classNames('txt-regular')} style={{ textAlign: 'right' }}>
                              {getAddressSummary(reportList.drivingList[0]['PassengerPhoneNo'])}
                              </div>
                            </span>
                          </span>
                        </div>



                        {row.all[5].indexOf(`cancel_`) >= 0 && (
                          <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                            <Button className={'bg-red'} style={{ width: '100%', height: '40px' }} onClick={() => { onClickCancelHandler(row.all[5]) }}>예약취소하기</Button>
                          </div>
                        )}

                        {/* {row.all[6].indexOf(`detail_`) >= 0 && (
                        <div className={classNames('payment-wrap')} style={{ alignItems: 'flex-start' }}>
                          <Button className={'bg-green'} style={{ width: '100%', height: '40px' }} onClick={() => { onClickSeeDetailHandler(row.all[6]) }}>상세보기</Button>
                        </div>
                      )} */}

                      </div>
                    </div>
                  </>
                )
              })}
            </WhiteBox>





          </div>
        )
        }



      </div>
      {showDescriptionIndex >= 0 && !isPC && (
        <MobileDescriptionPop
          title={'결제내역'}
          setShowDescriptionIndex={setShowDescriptionIndex}
        >
          <TableDescriptPopContentsCustom
            theadList={ReservationTableTitleList}
            tbody={rowList[showDescriptionIndex]}
            onClickCancel={onClickCancelHandler}
          />
        </MobileDescriptionPop>
      )}

      {/* <Button type='button' onClick={() => { setModalTest(true) }}>헬로우월드</Button> */}

      {/* <Modal
        isOpen={modalTest}
        onRequestClose={() => { setModalTest(false) }}
        contentLabel="Example Modal"
        overlayClassName=""
        className="modal"
        bodyOpenClassName="modal-scrolllock" >
        <section className="modal-container">
          <div className="modal">
            <div className="modal-head">
              <h1 className="modal-tit" tabindex="0">할증 요금 안내</h1>
            </div>
            <div className="modal-body">
              <div className="modal-contents">
                <div className="modal-contents-body">
                  <div className="component-wrap">
                    <div className="inner-container">

                      <div className="form-controls type-carinfo" style={{ paddingBottom: '10px' }}>
                        <div className="row">
                          <input type="text" name="customerName" id="" placeholder="차량 소유주 이름" title="" className='input type1' />
                          <input type="text" name="carNo" id="" placeholder="차량 번호 ex: 12가1234" title="" className='input type1' />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button className="btn-top-close" onClick={() => { setModalTest(false) }}>
              <span className="blind">팝업 닫기</span>
            </button>


          </div>
        </section>
      </Modal> */}
    </>
  )
}

export default ReservationHistory