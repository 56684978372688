import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReservationStepContent from '../../organisms/ReservationStepContent';
import ReservationItem from '../../molecules/ReservationItem';
import WhiteBox from '../../atoms/WhiteBox';
import PercentageGraph from '../../molecules/PercentageGraph';
import Input from '../../atoms/Input';
import ScheduleCalendar from '../../atoms/ScheduleCalendar';
import AddressInput from '../../molecules/AddressInput';
import Button from '../../atoms/Button';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TimeInput2 from '../../molecules/TimeInput2';
import { formErrorMessage } from '../../../utils/alertMessage';
import useLayout from '../../../hooks/useLayout';
import Modal from '../../molecules/Modal';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../utils/urls';
import { setContractOnedayHour } from '../../../reducers/reservation';
import { reservationHistoryData } from '../../../archive/temporaryData';
import useCheckPC from '../../../hooks/useCheckPC';
import UserInput from '../../molecules/UserInput';
import MyPageApi from '../../../lib/MyPageApi';
import ReserveApi from '../../../lib/ReserveApi'
import { useSelector } from 'react-redux';
import { isChkResultSuccess, getMasterAccountCustomerNo, getAddressSigungu, getReserveAvaliableDate, isChkCarNumber, isDev, getCookieInfo, DecryptAES, getNumber2Ciphers } from '../../../lib/Util';
import moment from 'moment';
import ReportApi from '../../../lib/ReportApi'
import { openLoading, closeLoading, openLoadingWithReserve, closeLoadingWithReserve } from '../../../reducers/loadingRedux'
import { addressRequest, addressRequestPlace } from '../../../lib/AddressApi';
import ReservationItemWithCheckbox from '../../molecules/ReservationItemWithCheckbox';
import Icon from '../../atoms/Icon';
import { getAddressDeduplication, isTimeOverlapping } from '../../../lib/Util';
import useDetectBrowser from '../../../hooks/useDetectBrowser';
import ReactModal from 'react-modal'

import TimeInput from '../../molecules/TimeInput';
import { handlePhoneChange } from '../../../utils/numbers';
import Checkbox from '../../atoms/CheckBox';
import { showModalAddressLoadList, showModalPassengerLoadEmpty, showModalPassengerLoadList } from './Modal/CreateReservation';
import AccountApi from '../../../lib/AccountApi';


/*
이용차량을 불러오기 위해서는
1. 우선 탑승자 정보가 지정 되어있어야 한다. (hidden값등에 customerno가 선택 되어있어야 할듯)
2. hidden에 customerno가 존재한다면 불러오기가 가능해야한다.

모바일일때에는 날짜 먼저 설정을 하면 안될 듯하다.
우선 탑승자를 선택할 수 있어야할 것 같음.

예약할 계정을 선택받아야 함. (UserPool 정보를 불러와서 선택해야 할듯.)
선택했으면 예약일을 선택하고,
선택되면 이후에는 기존대로 불러오기 등이 가능해야할 것 같음...

*/

/**
 * 이용 예약 페이지
 * name : createReservation
 * */

const CreateReservation = () => {

  // 전화번호 인증
  const [tmpAuthCode, setTmpAuthCode] = useState('')
  const [stepTwoFlag, setStepTwoFlag] = useState(false)
  const [AuthNumberButtonDisabled, SetAuthNumberButtonDisabled] = useState(false)
  const [NowTime, SetNowTime] = useState('')


  const { isPC } = useCheckPC();
  const { Os } = useDetectBrowser()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal, isModalOpen, closeModal } = useLayout();
  const [reservationDate, setReservationDate] = useState([]);
  const [firstVisit, setFirstVisit] = useState(true);
  const [completedModal, setCompletedModal] = useState(false);
  const [step, setStep] = useState(0)
  const [usageCheck, setUsageCheck] = useState(false)
  const [extendFlag, setExtendFlag] = useState(false)
  const [reserveLoading, setReserveLoading] = useState(true)
  const [reserveDateDetailFlag, setReserveDateDetailFlag] = useState(true)
  const [latestReserveInfo, setLatestReserveInfo] = useState({
    Passenger: '',
    PassengerPhoneNo: '',
    CarNumber: '',
    CarModel: ''
  })
  const [mmCustomerInfo, setMmCustomerInfo] = useState({
    Id: 0,
    CustomerNo: 0,
    GroupNumber: 1,
    ContractStartDate: "2022-09-26T00:00:00",
    Month: 0,
    ContractEndDate: "2024-05-25T00:00:00",
    ContractRangeType: 0,
    SuspendDay: 0,
    ContractTypeDay: 0,
    ContractTypeHour: 0,
    OvercarriedMinute: 0,
    ContractOneDayAvaliableHour: 1,
    MmHourPrice: 0,
    ExtrafeeFlagArea: 0,
    ExtrafeeFlagNight: 0,
    LiveFlag: 0,
    ContractRemark: "",
    DeleteYN: "N",
    RegDt: "2022-09-26T14:27:27.74",
    UpdateDt: "2023-09-07T10:58:12.167",
    PrePaymentAmount: 0,
    PostPaymentAmount: 0,
    PrePaymentFlag: 0,
    PostPaymentFlag: 0,
    CustomerName: null,
    EmailAddress: null
  })

  const {
    getValues,
    handleSubmit,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      startHour: '00',
      startMinute: '00',
      endTime: '00:00',
      PassengerUserName: '',
      PassengerPhoneNo: '',
      CarModel: '',
      CarNumber: '',
      CustomerComment: '',
    }
  });

  const [loadName, setLoadName] = useState('')
  // const [CarList, setCarList] = useState([])
  const loginInfo = useSelector(state => state.Login);
  const [disabledReserveList, setDisabledReserveList] = useState([])
  const [ReservedList, setReservedList] = useState([])
  const [userSelect, setUserSelect] = useState([
    {
      AccountType: 'email',
      CustomerName: '',
      CustomerNo: 0,
      UserId: '',
      PhoneNumber: '',
      MemberType: null,
      ParentCustomerNo: null,
      Roles: null,
      UseType: null,
    }
  ])
  const [selectedCustomerNo, setSelectedCustomerNo] = useState(0)

  /* TODO: 고객 정보에서 예약 완료 된 날짜 리스트 가져오기 */
  const reservedDateList = ['2023-08-02', '2023-08-06', '2023-08-20'];

  const time = reservationHistoryData.useAgeHour.used
  const total = reservationHistoryData.useAgeHour.total

  const [viewModalData, setViewModalData] = useState({
    UserInfo: [{
      CustomerName: '',
      PhoneNumber: '',
    }],
    CarInfo: [{
      CustomerName: '',
      PhoneNumber: '',
    }],
    AddressList: [{
      CustomerName: '',
      PhoneNumber: '',
    }],
    ReserveList: [{
      CustomerName: '',
      PhoneNumber: '',
    }],
    AddressViewFlag: 0

  })
  const [avaliableData, SetAvaliableData] = useState({
    UseTime: 0,
    RemainTime: 0,
    AvaliableTime: 0,
    UsePercent: 0
  })

  const [settingModalStatus, setSettingModalStatus] = useState({
    UserInfoFlag: false,
    CarInfoFlag: false,
    StartAddressFlag: false,
    EndAddressFlag: false,
    WaypointAddressFlag: false,
    AddressFlag: false,
    ConfirmReserveFlag: false
  })
  const [dataLoadComplete, setDataLoadComplete] = useState(false)

  const reduxEndTime = useSelector((state) => state.Reservation.usageTime.endTime);

  const isChkPartnerLogin = () => {
    let cookieInfo = getCookieInfo()

    let lgi = JSON.parse(DecryptAES(cookieInfo?.lgi))
    let lgupi = JSON.parse(DecryptAES(cookieInfo?.lgupi))

    return (lgi.UseType === 3)
  }

  const getPartnerLoginedInfo = () => {
    let cookieInfo = getCookieInfo()

    return {
      User: JSON.parse(DecryptAES(cookieInfo?.lgi)),
      UserPool: JSON.parse(DecryptAES(cookieInfo?.lgupi))
    }
  }

  const unmount = () => {

    document.getElementById('booking_reservation_container').style.maxWidth = '1000px'

  }

  useEffect(() => {
    return () => unmount()
  }, [])

  useEffect(() => {
    async function getData() {
      dispatch(openLoading())

      try {

        // 파트너 로그인의 경우
        if (isChkPartnerLogin()) {
          setStep(0)
          setUserSelect(getPartnerLoginedInfo().UserPool)

          document.getElementById('booking_reservation_container').style.maxWidth = '1500px'

        }
        // 고객 로그인의 경우
        else {
          let result3 = await ReportApi.GetMmAvaliablePercent(getMasterAccountCustomerNo(loginInfo.UserPool))
          if (isChkResultSuccess(result3)) {
            SetAvaliableData(result3.Item)
          }
          let result1 = await ReserveApi.MmCustomerInfo(getMasterAccountCustomerNo(loginInfo.UserPool))
          if (isChkResultSuccess(result1)) {
            setMmCustomerInfo(result1.Item.MmData)
            setLatestReserveInfo(result1.Item.LatestData)

            const tmpList = [];
            for (let i in result1?.Item?.ReserveDate) {
              tmpList.push(result1?.Item?.ReserveDate[i].ReserveDateData)
            }

            setDisabledReserveList(tmpList)
            setReservedList(result1?.Item?.ReserveDate)

            dispatch(setContractOnedayHour(result1.Item.MmData.ContractOneDayAvaliableHour))
            if (result1.Item.MmData.CustomerNo === 0) {
              setUsageCheck(true)
            }
          }

          // // 차량 정보가 존재하지 않는다면 차량 등록하라고 나와야 함.
          // let result = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
          // if (isChkResultSuccess(result)) {
          //   if (result?.Item?.CarInfoList?.length >= 1) {
          //     setCarList(result?.Item?.CarInfoList)
          //   }
          //   else {
          //     alert('이용차량 정보가 없습니다.\n마이페이지에서 차량을 등록해주세요.')
          //     navigate(URLS.RESERVATION_MY_PAGE)
          //   }
          // }
        }

      }
      catch (e) {
        window.location.reload()
      }

      dispatch(closeLoading())

    }
    getData();

  }, [])

  useEffect(() => {
    if (firstVisit) {
      openModal({
        title: '월간모시러 첫 이용을 환영합니다.',
        closeBtn: true,
        confirmBtn: true,
      })
    }
    if (completedModal) {
      openModal({
        title: '예약이 완료되었습니다.',
        closeBtn: true,
        confirmBtn: true,
      })
    }
  }, [firstVisit, completedModal])

  useEffect(() => {
    // setValue('PassengerUserName', latestReserveInfo.Passenger)
    // setValue('PassengerPhoneNo', latestReserveInfo.PassengerPhoneNo)
    // setValue('CarModel', latestReserveInfo.CarModel)
    // setValue('CarNumber', latestReserveInfo.CarNumber)


  }, [latestReserveInfo])

  const updateCustomerInfo = async () => {
    let retData = false;
    try {
      dispatch(openLoading())
      let result3 = await ReportApi.GetMmAvaliablePercent(selectedCustomerNo)
      if (isChkResultSuccess(result3)) {
        SetAvaliableData(result3.Item)
      }
      let result1 = await ReserveApi.MmCustomerInfo(selectedCustomerNo)
      if (isChkResultSuccess(result1)) {
        setMmCustomerInfo(result1.Item.MmData)
        setLatestReserveInfo(result1.Item.LatestData)

        const tmpList = [];
        for (let i in result1?.Item?.ReserveDate) {
          tmpList.push(result1?.Item?.ReserveDate[i].ReserveDateData)
        }

        setDisabledReserveList(tmpList)
        setReservedList(result1?.Item?.ReserveDate)

        dispatch(setContractOnedayHour(result1.Item.MmData.ContractOneDayAvaliableHour))
        if (result1.Item.MmData.CustomerNo === 0) {
          setUsageCheck(true)
        }
      }

      // 차량 정보가 존재하지 않는다면 차량 등록하라고 나와야 함.
      // let result = await MyPageApi.GetMyPage(selectedCustomerNo)
      // if (isChkResultSuccess(result)) {
      //   if (result?.Item?.CarInfoList?.length >= 1) {
      //     setCarList(result?.Item?.CarInfoList)
      //   }
      //   else {
      //     alert('이용차량 정보가 없습니다.\n예약할 수 없습니다.\n모시러 컨시어지로 문의주세요.')
      //     dispatch(closeLoading())
      //     return false;
      //   }
      // }
      retData = true;
    }
    catch (e) {

    }

    dispatch(closeLoading())
    return retData;

  }

  const modalStatusChange = (id, data) => {
    setSettingModalStatus({
      ...settingModalStatus,
      [id]: data
    })
  }

  const ShowModalManage = (type, flag) => {

    if (type.indexOf('UserInfoFlag') >= 0) {
      return (
        <>
          {flag && <ReactModal
            isOpen={settingModalStatus.UserInfoFlag}
            onRequestClose={() => {
              setViewModalData({
                ...viewModalData,
                UserInfo: []
              })
              modalStatusChange('UserInfoFlag', false);

            }}
            className={'modal'}

            subTitle={''}>
            <div className={classNames('title-set')} style={{ fontSize: '18px' }}>
              탑승자 정보 불러오기
            </div>
            <div className={classNames('main-set')}>
              {viewModalData.UserInfo.map((item, idx) => {
                return (
                  <div key={idx} className='popup-flex'>
                    <div>{item?.CustomerName}</div>
                    <div>{item?.PhoneNumber}</div>
                    <div><Button
                      styleType='secondary'
                      onClick={() => {
                        setValue('PassengerUserName', item?.CustomerName)
                        setValue('PassengerPhoneNo', item?.PhoneNumber)
                        modalStatusChange('UserInfoFlag', false);

                      }}>선택</Button></div>
                  </div>
                )
              })}

            </div>
            <div className={classNames('button-set')}>
              <Button
                onClick={() => {
                  setViewModalData({
                    ...viewModalData,
                    UserInfo: []
                  })
                  modalStatusChange('UserInfoFlag', false);

                }}
              >
                닫기
              </Button>
            </div>
          </ReactModal>}
        </>
      )
    }
    else if (type.indexOf('CarInfoFlag') >= 0) {
      return (
        <>
          {flag && <ReactModal
            isOpen={settingModalStatus.CarInfoFlag}
            onRequestClose={() => {
              setViewModalData({
                ...viewModalData,
                CarInfo: []
              })
              modalStatusChange('CarInfoFlag', false);

            }}
            className={'modal'}

            title={'차량정보 불러오기'}
            subTitle={''}>
            <div className={classNames('title-set')} style={{ fontSize: '18px' }}>
              차량정보 불러오기
            </div>


            <div className={classNames('main-set')}>
              {viewModalData.CarInfo.map((item, idx) => {
                return (
                  <div key={idx} className='popup-flex'>
                    <div>{item?.CarNumber}</div>
                    <div>{item?.CarModel}</div>
                    <div><Button
                      styleType='secondary'
                      onClick={() => {
                        setValue('CarNumber', item?.CarNumber)
                        setValue('CarModel', item?.CarModel)
                        setValue('vehicle', `${item?.CarNumber} / ${item?.CarModel}`)

                        modalStatusChange('CarInfoFlag', false);

                      }}>선택</Button></div>
                  </div>
                )
              })}

            </div>
            <div className={classNames('button-set')}>
              <Button
                onClick={() => {
                  setViewModalData({
                    ...viewModalData,
                    CarInfo: []
                  })
                  modalStatusChange('CarInfoFlag', false);

                }}
              >
                닫기
              </Button>
              {/* {CarList.length < 2 && <>
                <>
                  {!isChkPartnerLogin() &&
                    <Button
                      onClick={() => {
                        navigate(`${URLS.RESERVATION_MY_PAGE}?flag=reserve_carinfoset`)
                      }} >
                      추가하기
                    </Button>}
                </>
              </>} */}

            </div>
          </ReactModal>}
        </>
      )
    }
    else if (type.indexOf('AddressFlag') >= 0) {
      return (
        <>
          {flag && <ReactModal
            isOpen={settingModalStatus.AddressFlag}
            onRequestClose={() => {
              setViewModalData({
                ...viewModalData,
                AddressList: [],
                ReserveList: [],
                AddressViewFlag: 0
              })
              modalStatusChange('AddressFlag', false);


            }}
            className={'modal'}

            subTitle={''}>
            <div className={classNames('title-set')} style={{ fontSize: '18px' }}>
              주소정보 불러오기
            </div>
            <div className={classNames('main-set')}>
              <div style={{ height: '250px', overflowY: 'auto' }}>
                {viewModalData.AddressList.map((item, idx) => {
                  return (
                    <div key={idx} className='popup-flex' style={{ padding: '5px 0px', borderBottom: '2px solid #ebebeb' }}>
                      <div style={{ width: '60px' }}>{item?.Alias}</div>
                      <div style={{ width: '120px' }}>{item?.Address}</div>
                      <div style={{ width: '60px' }}>
                        <Button
                          styleType='secondary'
                          onClick={() => {
                            setValue(loadName, item?.Address)

                            modalStatusChange('AddressFlag', false);

                          }}>선택</Button></div>
                    </div>
                  )
                })}
                {getAddressDeduplication(viewModalData.ReserveList).map((item, idx) => {
                  return (
                    <div key={idx} className='popup-flex' style={{ padding: '5px 0px' }}>
                      <div style={{ width: '60px' }}>&nbsp;</div>
                      <div style={{ width: '120px' }}>{item?.Address}</div>
                      <div style={{ width: '60px' }}>
                        <Button
                          styleType='secondary'
                          onClick={() => {
                            setValue(loadName, item?.Address)

                            modalStatusChange('AddressFlag', false);

                          }}>선택</Button></div>
                    </div>
                  )
                })}
              </div>

            </div>
            <div className={classNames('button-set')}>
              <Button
                onClick={() => {
                  setViewModalData({
                    ...viewModalData,
                    AddressList: [],
                    ReserveList: [],
                    AddressViewFlag: 0
                  })
                  modalStatusChange('AddressFlag', false);

                }}
              >
                닫기
              </Button>
            </div>
          </ReactModal>}
        </>
      )
    }
    else if (type.indexOf('ConfirmReserveFlag') >= 0) {
      return (
        <>
          {flag && <ReactModal
            isOpen={settingModalStatus.ConfirmReserveFlag}
            onRequestClose={() => {

              modalStatusChange('ConfirmReserveFlag', false);


            }}
            className={'modal'}

            subTitle={''}>
            <div className={classNames('title-set')} style={{ fontSize: '18px' }}>
              예약 확인
            </div>
            <div className={classNames('main-set')}>
              <div style={{ height: '250px', overflowY: 'auto' }}>
                {viewModalData.AddressList.map((item, idx) => {
                  return (
                    <div key={idx} className='popup-flex' style={{ padding: '5px 0px', borderBottom: '2px solid #ebebeb' }}>
                      <div style={{ width: '60px' }}>{item?.Alias}</div>
                      <div style={{ width: '120px' }}>{item?.Address}</div>
                      <div style={{ width: '60px' }}>
                        <Button
                          styleType='secondary'
                          onClick={() => {
                            setValue(loadName, item?.Address)

                            modalStatusChange('ConfirmReserveFlag', false);

                          }}>선택</Button></div>
                    </div>
                  )
                })}
                {getAddressDeduplication(viewModalData.ReserveList).map((item, idx) => {
                  return (
                    <div key={idx} className='popup-flex' style={{ padding: '5px 0px' }}>
                      <div style={{ width: '60px' }}>&nbsp;</div>
                      <div style={{ width: '120px' }}>{item?.Address}</div>
                      <div style={{ width: '60px' }}>
                        <Button
                          styleType='secondary'
                          onClick={() => {
                            setValue(loadName, item?.Address)

                            modalStatusChange('ConfirmReserveFlag', false);

                          }}>선택</Button></div>
                    </div>
                  )
                })}
              </div>

            </div>
            <div className={classNames('button-set')}>
              <Button
                onClick={() => {
                  setViewModalData({
                    ...viewModalData,
                    AddressList: [],
                    ReserveList: [],
                    AddressViewFlag: 0
                  })
                  modalStatusChange('ConfirmReserveFlag', false);

                }}
              >
                닫기
              </Button>
            </div>
          </ReactModal>}
        </>
      )
    }
  }

  // 저장버튼 눌렀을 때의 처리인듯.
  const onSubmit = handleSubmit(async data => {

    let validCheck = true;

    if (isChkPartnerLogin()) {
      if (!dataLoadComplete) {
        alert('STEP 0 - 고객선택에서\n고객 선택 불러오기를 눌러주세요.')
        setStep(0)
        return;
      }
    }

    if (usageCheck) {
      alert('현재 이용일 전 입니다.\n이용일이 되면 예약이 활성화 됩니다.')
      return;
    }

    if (reservationDate.length <= 0) {
      alert('예약일자를 하나 이상 선택해주세요')
      return;
    }

    /** 탑승자 정보 validation **/
    if (!data?.PassengerUserName) {
      validCheck = false;
      setError('PassengerUserName', {
        type: 'focus',
        name: 'PassengerUserName',
        message: formErrorMessage.passengerName
      }, { shouldFocus: true });
    }

    /** 탑승자 정보 validation **/
    if (!data?.PassengerPhoneNo) {
      validCheck = false;
      setError('PassengerPhoneNo', {
        type: 'focus',
        name: 'PassengerPhoneNo',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }

    if (!data?.serviceAreaDepartAddress1) {
      validCheck = false;
      setError('serviceAreaDepartAddress1', {
        type: 'focus',
        name: 'serviceAreaDepartAddress1',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    if (!data?.serviceAreaEndAreaAddress1) {
      validCheck = false;
      setError('serviceAreaEndAreaAddress1', {
        type: 'focus',
        name: 'serviceAreaEndAreaAddress1',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    if (!data?.serviceAreaDestinationAddress1) {
      validCheck = false;
      setError('serviceAreaDestinationAddress1', {
        type: 'focus',
        name: 'serviceAreaDestinationAddress1',
        message: formErrorMessage.address1Error
      }, { shouldFocus: true });
    }

    if (!isChkCarNumber(data?.CarNumber)) {
      validCheck = false;
      setError('CarNumber', {
        type: 'focus',
        name: 'CarNumber',
        message: formErrorMessage.carNumberError2
      }, { shouldFocus: true });
    }

    if (!data?.CarNumber) {
      validCheck = false;
      setError('CarNumber', {
        type: 'focus',
        name: 'CarNumber',
        message: formErrorMessage.carNumberError
      }, { shouldFocus: true });
    }

    if (!data?.CarModel) {
      validCheck = false;
      setError('CarModel', {
        type: 'focus',
        name: 'CarModel',
        message: formErrorMessage.carModelError
      }, { shouldFocus: true });
    }


    // 예약일자 선택 체크
    if (!data?.CarModel) {
      validCheck = false;
      setError('CarModel', {
        type: 'focus',
        name: 'CarModel',
        message: formErrorMessage.carModelError
      }, { shouldFocus: true });
    }

    // // 차량 체크
    // // 차량1대 이상
    // if (CarList.length >= 1) {
    //   // 차량 리스트에서 일치하는 번호가 없을 경우
    //   if (CarList.find((x) => x?.CarNumber === data?.CarNumber) === undefined) {
    //     validCheck = false;
    //     alert('등록되어있는 차량 이외의 차량은 예약할 수 없습니다.')
    //     setError('CarNumber', {
    //       type: 'focus',
    //       name: 'CarNumber',
    //       message: '등록되어있지 않는 차량번호입니다. 불러오기 누르고 등록되어있는 차량을 선택해주세요.'
    //     }, { shouldFocus: true });
    //     return;
    //   }
    // }
    // // 등록 차량 없음
    // else {
    //   validCheck = false;
    //   if (isChkPartnerLogin()) {
    //     alert('이용차량 정보가 없습니다.\n예약할 수 없습니다.\n모시러 컨시어지로 문의주세요.')
    //   }
    //   else {
    //     alert('이용차량 정보가 없습니다.\n마이페이지에서 차량을 등록해주세요.')
    //     navigate(URLS.RESERVATION_MY_PAGE)
    //   }

    //   return;
    // }

    try {
      if (validCheck) {
        clearErrors('CarNumber')
        let tmpArr = []
        let tmpLatLng = {
          StartLat: '',
          StartLng: '',
          EndLat: '',
          EndLng: '',
          WaypointLat: '',
          WaypointLng: ''
        }

        dispatch(openLoadingWithReserve())

        let adr_result1 = await addressRequestPlace(data?.serviceAreaDepartAddress1)
        if (adr_result1.meta.total_count === 0) {
          adr_result1 = await addressRequest(data?.serviceAreaDepartAddress1)
        }
        if (adr_result1?.documents?.length) {
          tmpLatLng.StartLat = adr_result1?.documents[0]?.y
          tmpLatLng.StartLng = adr_result1?.documents[0]?.x
        }
        else {
          alert('출발지 주소를 정확하게 입력해주세요.')
          dispatch(closeLoadingWithReserve())
          return
        }

        let adr_result2 = await addressRequestPlace(data?.serviceAreaEndAreaAddress1)
        if (adr_result2.meta.total_count === 0) {
          adr_result2 = await addressRequest(data?.serviceAreaEndAreaAddress1)
        }
        if (adr_result2?.documents?.length) {
          tmpLatLng.EndLat = adr_result2?.documents[0]?.y
          tmpLatLng.EndLng = adr_result2?.documents[0]?.x
        }
        else {
          alert('종료지 주소를 정확하게 입력해주세요.')
          dispatch(closeLoadingWithReserve())
          return
        }

        let adr_result3 = await addressRequestPlace(data?.serviceAreaDestinationAddress1)
        if (adr_result3.meta.total_count === 0) {
          adr_result3 = await addressRequest(data?.serviceAreaDestinationAddress1)
        }
        if (adr_result3?.documents?.length) {
          tmpLatLng.WaypointLat = adr_result3?.documents[0]?.y
          tmpLatLng.WaypointLng = adr_result3?.documents[0]?.x
        }
        else {
          alert('목적지 주소를 정확하게 입력해주세요.')
          dispatch(closeLoadingWithReserve())
          return
        }

        let overlapArr = []

        for (let i in reservationDate) {
          let momentStartDt = moment(`${moment(reservationDate[i]).format('yyyy-MM-DD')} ${data.startHour}:${data.startMinute}`)
          let momentEndDt = moment(reduxEndTime.nextDayFlag ? `${moment(reservationDate[i]).add(1, 'days').format('yyyy-MM-DD')} ${data.endTime}` : `${moment(reservationDate[i]).format('yyyy-MM-DD')} ${data.endTime}`)
          // 겹치는 시간 파악
          if (isTimeOverlapping(ReservedList, momentStartDt, momentEndDt)) {
            overlapArr.push(momentStartDt.format('yyyy-MM-DD'))
            continue;
          }

          tmpArr.push({
            CustomerNo: isChkPartnerLogin() ? selectedCustomerNo : getMasterAccountCustomerNo(),
            RevStartDt: `${moment(reservationDate[i]).format('yyyy-MM-DD')} ${data.startHour}:${data.startMinute}`,
            RevEndDt: reduxEndTime.nextDayFlag ? `${moment(reservationDate[i]).add(1, 'days').format('yyyy-MM-DD')} ${data.endTime}` : `${moment(reservationDate[i]).format('yyyy-MM-DD')} ${data.endTime}`,
            StartAddress: data.serviceAreaDepartAddress1,
            StartLat: tmpLatLng.StartLat,
            StartLng: tmpLatLng.StartLng,
            EndAddress: data.serviceAreaEndAreaAddress1,
            EndLat: tmpLatLng.EndLat,
            EndLng: tmpLatLng.EndLng,
            WaypointAddress: data.serviceAreaDestinationAddress1,
            WaypointLat: tmpLatLng.WaypointLat,
            WaypointLng: tmpLatLng.WaypointLng,
            DrivingType: '1',
            DrivingStatus: '1',
            PaymentType: 1,
            PaymentYn: 'N',
            CustomerComment: extendFlag ? '해당 운행건은 연장 가능성이 있습니다.\n' + data.CustomerComment : data.CustomerComment,
            RegDt: `${moment().format('yyyy-MM-DD HH:mm')}`,
            CarNumber: data.CarNumber,
            CarModel: data.CarModel,
            CarMemo: '',
            Passenger: data?.PassengerUserName,
            PassengerPhoneNo: data?.PassengerPhoneNo,
            ChargeAmount: 0,
            StartAreaFee: 0,
            AreaFee: 0,
            NightFee: 0,
            PayServiceFee: 0,
            ExpensesTotalAmount: 0,
            ReserveCharge: 0,
            ReserveDiscountAmount: 0,
            DelYn: 'N',
            PaidTicketYN: 'N',
            CardNumber: '',
            HourPrice: 0,
            BizCompNo: 0,
            CouponId: 0,
            DiscountAmount: 0,
            DiscountRate: 0,
            ReserveType: 5,
            ReserveRealAmount: 0,
            BillingYN: 'N',
            CustomerParkingStatus: 0,

            ReserveHostType: getPartnerLoginedInfo().User.UseType,
            ReserveHostNo: getPartnerLoginedInfo().User.CustomerNo,
            isPartner: isChkPartnerLogin()

          })
        }


        // console.log(tmpArr)
        // return;
        if (overlapArr.length >= 1) {
          alert(`아래 날짜에 예약건이 겹쳐서 예약할 수 없었습니다.\n날짜를 확인하시고 수정해주세요.\n\n${overlapArr.join('\n')}`)
          dispatch(closeLoadingWithReserve())
          return
        }

        let msg = `[예약확인]\n1) 예약일자\n${reservationDate.map((data) => (moment(data).format('yyyy-MM-DD') + '\n'))}\n2) 시간\n${data.startHour}:${data.startMinute} ~ ${reduxEndTime.nextDayFlag ? '익일' : ''} ${data.endTime}\n\n3) 주소\n- 출발지 : ${data.serviceAreaDepartAddress1}\n- 종료지 : ${data.serviceAreaEndAreaAddress1}\n- 목적지 : ${data.serviceAreaDestinationAddress1}\n\n예약접수를 진행합니다.\n계속하시겠습니까?`

        if (window.confirm(msg)) {

          let result = {}
          if (isDev()) {
            // 개발중이라면 아래 호출
            result = await ReserveApi.SetDrivingReservationMultipleAsync(tmpArr)
          }
          else {
            // 개발중 기능이 검증완료 되면 반영.
            // result = await ReserveApi.SetDrivingReservationMultiple(tmpArr)
            result = await ReserveApi.SetDrivingReservationMultipleAsync(tmpArr)
          }

          if (isChkResultSuccess(result)) {
            setCompletedModal(true);
          }
          else {
            alert(result.Message)
          }
          dispatch(closeLoadingWithReserve())


          setFirstVisit(false);
        }
        else {
          dispatch(closeLoadingWithReserve())
        }



      }
    } catch (error) {
      alert('아래 문제가 발생했습니다.\n' + error)
      dispatch(closeLoadingWithReserve())
    }

  })

  const BtnLoadInfo = async (Type, formName = null) => {

    if (isChkPartnerLogin()) {
      if (!dataLoadComplete) {
        alert('STEP 0 - 고객선택에서\n고객 선택 불러오기를 눌러주세요.')
        setStep(0)
        return;
      }
    }



    let info = getPartnerLoginedInfo()

    if (Type === 'UserInfo') {
      let result = {}

      if (isChkPartnerLogin()) {
        result = await MyPageApi.GetMyPage(selectedCustomerNo)
      }
      else {
        result = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
      }
      if (isChkResultSuccess(result)) {
        if (isDev()) {
          let tmpArr = result.Item.MemberList?.filter((data) => data.UseType === 1)
          // let tmpArr = []
          // 실탑승자 크기가 1 이상일때
          if (tmpArr.length >= 1) {
            let alertResult = await showModalPassengerLoadList(tmpArr, (name, phone) => {
              setValue('PassengerUserName', name)
              setValue('PassengerPhoneNo', phone)
            })
          }
          else {
            let alertResult = await showModalPassengerLoadList(tmpArr, () => { })
            if (alertResult.isConfirmed) {
              // 화면 이동.
              alert('화면 이동 필요.')
            }
          }
        }
        else {
          setViewModalData({
            ...viewModalData,
            UserInfo: result.Item.MemberList?.filter((data) => data.UseType === 1)
          })
          modalStatusChange('UserInfoFlag', true);
        }



      }
      else {
        alert('불러오기 팝업을 띄울 수 없습니다.')
      }


    }
    else if (Type === 'CarInfo') {
      let result = {}
      if (isChkPartnerLogin()) {
        result = await MyPageApi.GetMyPage(selectedCustomerNo)
      }
      else {
        result = await MyPageApi.GetMyPage(getMasterAccountCustomerNo(loginInfo.UserPool))
      }

      if (isChkResultSuccess(result)) {
        setViewModalData({
          ...viewModalData,
          CarInfo: result.Item.CarInfoList
        })
        modalStatusChange('CarInfoFlag', true);
      }
      else {
        alert('불러오기 팝업을 띄울 수 없습니다.')
      }

    }
    else if (Type === 'Address') {
      let result = {}

      if (isChkPartnerLogin()) {
        result = await ReserveApi.GetAddressReserveList(selectedCustomerNo)
      }
      else {
        result = await ReserveApi.GetAddressReserveList(getMasterAccountCustomerNo(loginInfo.UserPool))
      }

      if (isChkResultSuccess(result)) {
        if (isDev()) {
          let tmpAdrArr = result.Item.addressList
          let tmpRevArr = result.Item.reserveList
          // let tmpArr = []
          // 실탑승자 크기가 1 이상일때
          if (tmpAdrArr.length >= 1 || tmpRevArr.length >= 1) {
            let alertResult = await showModalAddressLoadList(tmpAdrArr, tmpRevArr, (address) => {
              setValue(formName, address)
            })
          }
          else {
            let alertResult = await showModalAddressLoadList(tmpAdrArr, tmpRevArr, () => { })
            if (alertResult.isConfirmed) {
              // 화면 이동.
              alert('화면 이동 필요.')
            }
          }
        }
        else {
          setViewModalData({
            ...viewModalData,
            AddressList: result.Item.addressList,
            ReserveList: result.Item.reserveList,
          })

          if (formName !== null)
            setLoadName(formName)

          modalStatusChange('AddressFlag', true);
        }

      }
      else {
        alert('불러오기 팝업을 띄울 수 없습니다.')
      }

    }


  }
  /*
    const handleAddressChange2 = (subField, value, target) => {
      setSelectedOptions((prevState) => ({
        ...prevState,
        userInformation: {
          ...prevState.userInformation,
          user: {
            ...prevState.userInformation.user,
            [target]: value
          },
        },
      }));
    };
  */

  const handleAddress = (e, e2, e3) => {

  }

  const setSelectedCustomer = async () => {

    if (selectedCustomerNo === 0) {
      alert('고객을 선택해주세요.')
    }
    else {
      let result = await updateCustomerInfo()
      if (result) {
        setStep(1)
        setDataLoadComplete(true)
      }

    }

  }


  const checkChange = (e) => {
    if (e) {
      setValue('serviceAreaEndAreaAddress1', getValues('serviceAreaDepartAddress1'))
    }
    else {
      setValue('serviceAreaEndAreaAddress1', '')
    }
  }

  const checkChange2 = (e) => {
    setExtendFlag(e)
  }

  const InfomationText = (text, topMargin) => (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', marginTop: topMargin + 'px' }}>
      <div>
        <Icon icon={'bul_exclamation'} style={{ width: '15px', height: '15px' }} />
      </div>
      <div>
        <span style={{ fontSize: '12px' }}>
          {text}
        </span>
      </div>
    </div>
  )




  /* 탑승자 정보 입력 전번 인증 관련 */

  const setJasminCustomerStep0 = async () => {
    if (!AuthNumberButtonDisabled) {
      alert('전화번호 인증을 진행해주세요.')
    }
    else {
      setStep(1)
    }
  }

  // 인증 버튼 눌렀을때
  const handleAuthCodeCheck = async () => {
    let authNumber = getValues('authNumber')
    let phone = getValues('phonenumber')
    let validCheck = true;

    if (!authNumber?.length) {
      validCheck = false;
      setError('authNumber', {
        type: 'focus',
        name: 'authNumber',
        message: '인증번호를 입력해주세요.'
      }, { shouldFocus: true });
    }
    if (validCheck) {

      if (authNumber === tmpAuthCode) {
        try {
          clearErrors('authNumber')
          SetAuthNumberButtonDisabled(true)
          clearInterval(window.timer)
        }
        catch (e) {
          console.log(e)
        }
      }
      else {
        setError('authNumber', {
          type: 'focus',
          name: 'authNumber',
          message: '인증번호가 일치하지 않습니다.'
        }, { shouldFocus: true });
      }
    }
  }


  const limitTimer = () => {
    let time = 10;   // 3분
    let min = '';
    let sec = '';
    window.timer = setInterval(() => {
      min = parseInt(time / 60);
      sec = time % 60;
      SetNowTime(`${getNumber2Ciphers(min)}:${getNumber2Ciphers(sec)}`)
      time--;

      if (time < 0) {

        alert('인증 시간이 지났습니다. 다시 인증해 주세요.')
        clearErrors('authNumber')
        setStepTwoFlag(false)
        SetNowTime('')
        setValue('authNumber', '')
        clearInterval(window.timer)
      }
    }, 1000);
  }


  const handlePhoneAuth = async () => {
    let phone = getValues('PassengerPhoneNo')
    let validCheck = true;

    if (!phone?.length) {
      validCheck = false;
      setError('PassengerPhoneNo', {
        type: 'focus',
        name: 'PassengerPhoneNo',
        message: formErrorMessage.phoneError
      }, { shouldFocus: true });
    }
    if (validCheck) {
      try {
        if (stepTwoFlag) {
          alert('현재 전화번호 인증을 진행중입니다.\n인증번호를 입력해주세요')
        }
        else {
          let result = await AccountApi.SetVerifySms(phone)
          if (isChkResultSuccess(result)) {
            alert('입력하신 전화번호로 인증번호를 발송했습니다.')
            clearErrors('PassengerPhoneNo')
            setStepTwoFlag(true)
            setTmpAuthCode(result.Message)
            SetAuthNumberButtonDisabled(false)
            limitTimer()
          }
          else {
            alert(result.Message)
          }
        }
        

      }
      catch (e) {
        console.log(e)
      }
    }
  }


  return (
    <>
      {ShowModalManage('UserInfoFlag', settingModalStatus.UserInfoFlag)}
      {ShowModalManage('CarInfoFlag', settingModalStatus.CarInfoFlag)}
      {ShowModalManage('AddressFlag', settingModalStatus.AddressFlag)}
      {ShowModalManage('ConfirmReserveFlag', settingModalStatus.ConfirmReserveFlag)}

      <form className={classNames('create-reservation')} onSubmit={onSubmit}>

        {/* 파트너 로그인인가? */}
        {isChkPartnerLogin() ?
          <>
            {/* step이 0인 경우는 파트너일 경우만 해당. */}
            {(isPC || (!isPC && step === 0)) && (
              <ReservationStepContent
                title={'STEP 0'}
                subtitle={'고객선택'}
              >
                {/** 이용 시간 **/}
                <ReservationItem title={'고객 선택 후, 불러오기를 눌러주세요.'} >
                  <WhiteBox>
                    {userSelect.map((data, idx) => {
                      return (
                        <div
                          className={classNames('partner-user-select', selectedCustomerNo === data.CustomerNo ? 'selected' : '')}
                        >
                          <div>{data.CustomerName}</div>
                          <div>{data.PhoneNumber}</div>
                          <div>
                            <Button
                              type={'button'}
                              styleType={'secondary'}
                              classes={'button-small'}
                              onClick={() => {
                                setSelectedCustomerNo(data.CustomerNo)
                              }}
                            >
                              선택
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </WhiteBox>
                </ReservationItem>
                {isPC && (
                  <Button
                    styleType={'secondary'}
                    onClick={setSelectedCustomer}
                  >불러오기</Button>
                )}
              </ReservationStepContent>
            )}
          </> :
          <>
            {/* 자스민 고객인가 */}
            {(isPC || (!isPC && step === 0)) && (
              <ReservationStepContent
                title={'STEP 0'}
                subtitle={'탑승자 정보 입력'}
              >
                {/** 이용 시간 **/}
                <ReservationItem title={'정보 입력 후 인증을 진행해주세요.'} >

                  <Input
                    type={'text'}
                    required
                    {...register('PassengerUserName')}
                    error={errors.PassengerUserName}
                    setValue={setValue}
                    onChange={e => {
                      setValue('PassengerUserName', e.target.value)
                    }}
                    placeholder={'이름'}
                  />
                  <div>&nbsp;</div>
                  <Input
                    type={'text'}
                    required
                    {...register('PassengerPhoneNo')}
                    setValue={setValue}
                    error={errors.PassengerPhoneNo}
                    onChange={e => {
                      setValue('PassengerPhoneNo', e.target.value)
                      // handleAddressChange('PassengerPhoneNo', e.target.value, target);
                    }}
                    placeholder={'전화번호'}
                  />
                </ReservationItem>
                <Button
                  type={'button'}
                  styleType={'secondary'}
                  classes={'bold txt-large'}
                  onClick={handlePhoneAuth}
                >전화번호인증</Button>

                {stepTwoFlag && (<>
                  <div className={classNames('input-set')}>
                    <span className={classNames('txt-large')}>
                      인증번호 입력
                    </span>
                    <Input
                      type={'text'}
                      required
                      {...register('authNumber')}
                      setValue={setValue}
                      error={errors.authNumber}
                      onChange={(e) => {
                        setValue('authNumber', e.target.value)
                      }}
                      placeholder={'인증번호 입력'}
                      disabled={AuthNumberButtonDisabled}

                    />
                    <span className={classNames('txt-regular')}>
                      {AuthNumberButtonDisabled ? '인증이 완료되었습니다.' : `제한시간 ${NowTime}`}
                    </span>
                  </div>

                  <Button
                    type={'button'}
                    styleType={AuthNumberButtonDisabled ? 'primary' : 'secondary'}
                    classes={'bold txt-large'}
                    onClick={handleAuthCodeCheck}
                    disabled={AuthNumberButtonDisabled}
                    style={AuthNumberButtonDisabled ? { backgroundColor: '#cbcbcb' } : {}}
                  >{AuthNumberButtonDisabled ? '인증완료' : '인증'}</Button>

                </>)}

                {isPC && (
                  <Button
                    styleType={'secondary'}
                    onClick={setSelectedCustomer}
                  >다음</Button>
                )}
              </ReservationStepContent>
            )}
          </>

        }




        {(isPC || (!isPC && step === 1)) && (
          <ReservationStepContent
            title={'STEP 1'}
            subtitle={'기본정보'}
          >

            {/** 예약 일자 선택 **/}
            <ReservationItem title={'예약일자선택'} >
              <ScheduleCalendar
                selectedDate={reservationDate}
                onChange={(newDate) => {
                  setValue('reservationDate', newDate);
                  setReservationDate(newDate);
                }}
                setDate={setReservationDate}
                reservedDateList={reservedDateList}
                VisibleStartDate={mmCustomerInfo.ContractStartDate}
                VisibleEndDate={mmCustomerInfo.ContractEndDate}
                DisabledReserveDate={disabledReserveList}
                isPartnerLogin={isChkPartnerLogin()}
              />
              {/* {InfomationText('예약 일자는 다수의 일로 선택 할 수 있습니다.', 15)}
              {InfomationText('각 일 선택 후 예약시, 예약한 일자마다 설정한 시간으로 일괄 접수됩니다.(N박N일 불가)', 5)} */}
            </ReservationItem>
          </ReservationStepContent>
        )}

        {(isPC || (!isPC && step === 2)) && (
          <ReservationStepContent
            title={'STEP 2'}
            subtitle={'일자별 예약 내용 입력'}
          >
            {/** 이용 고객명 **/}
            {/* <ReservationItem title={'탑승자 정보'} >
              <UserInput
                register={register}
                setValue={setValue}
                onButton={() => BtnLoadInfo('UserInfo')}
                // onButton={async () => {
                //   let result = await showModalPassengerLoadEmpty()
                //   if (result.isConfirmed) {
                //     alert('confirm btn')
                //   }
                //   console.log(result)
                  
                // }}
                nameError={errors.PassengerUserName}
                phoneError={errors.PassengerPhoneNo}
                selectAddress={true}
              />
            </ReservationItem> */}

            {/** 연락처 **/}
            {/* <ReservationItem title={'연락처'} >
              <Input
                type={'tel'}
                required
                setValue={setValue}
                maxLength={11}
                error={errors.phone}
                placeholder={'연락처'}
                {...register('phone')}
                onChange={e=>{
                  handlePhoneChange(e, setValue, 'phone')
                }}
                />
            </ReservationItem> */}

            {/** 시작시간 **/}
            <ReservationItem title={'시작시간'} >
              <TimeInput2
                start={true}
                setError={setError}
                register={register}
                setValue={setValue}
                getValues={getValues}

                startHourRegister={'startHour'}
                startMinuteRegister={'startMinute'}
              />
            </ReservationItem>

            {/** 종료시간 **/}
            <ReservationItem title={'종료시간'} >
              <TimeInput2
                end={true}
                setError={setError}
                register={register}
                setValue={setValue}
                getValues={getValues}

                TimeRegister={'endTime'}
                timeError={errors.endTime}
                errorMessage={errors?.endTime?.message}
              />
            </ReservationItem>

            {/** 출발지 **/}
            <ReservationItem title={'출발지'} >
              <AddressInput
                register={register}
                setValue={setValue}
                // zipError={errors.serviceAreaDepartZip}
                address1Error={errors.serviceAreaDepartAddress1}
                // address2Error={errors.serviceAreaDepartAddress2}
                // zipRegister={'serviceAreaDepartZip'}
                address1Register={'serviceAreaDepartAddress1'}
                // address2Register={'serviceAreaDepartAddress2'}
                target={'departure'}
                selectAddress={false}
                onButton={() => BtnLoadInfo('Address', 'serviceAreaDepartAddress1')}
                getValues={getValues}
                handleAddressChange={handleAddress}

              />

            </ReservationItem>

            {/** 종료지 **/}
            <ReservationItemWithCheckbox title={'종료지'} checkboxText={'출발지와 동일'} onCheckChange={checkChange}  >
              <AddressInput
                register={register}
                setValue={setValue}
                // zipError={errors.serviceAreaEndAreaZip}
                address1Error={errors.serviceAreaEndAreaAddress1}
                // address2Error={errors.serviceAreaEndAreaAddress2}
                // zipRegister={'serviceAreaEndAreaZip'}
                address1Register={'serviceAreaEndAreaAddress1'}
                // address2Register={'serviceAreaEndAreaAddress2'}
                target={'endArea'}
                selectAddress={false}
                onButton={() => BtnLoadInfo('Address', 'serviceAreaEndAreaAddress1')}
                getValues={getValues}
                handleAddressChange={handleAddress}
              />
            </ReservationItemWithCheckbox>

            {/** 목적지 **/}
            <ReservationItem title={'목적지'} >
              <AddressInput
                register={register}
                setValue={setValue}
                // zipError={errors.serviceAreaDestinationZip}
                address1Error={errors.serviceAreaDestinationAddress1}
                // address2Error={errors.serviceAreaDestinationAddress2}
                // zipRegister={'serviceAreaDestinationZip'}
                address1Register={'serviceAreaDestinationAddress1'}
                // address2Register={'serviceAreaDestinationAddress2'}
                target={'destination'}
                selectAddress={false}
                onButton={() => BtnLoadInfo('Address', 'serviceAreaDestinationAddress1')}
                getValues={getValues}
                handleAddressChange={handleAddress}
              />
            </ReservationItem>

            {/** 이용차량 **/}
            <ReservationItem title={'이용차량'} >
              <div>
                <div className={'input-wrap input-with-button'} style={{ paddingBottom: '10px' }}>
                  <Input
                    type={'text'}
                    required
                    {...register('CarNumber')}
                    error={errors.CarNumber}
                    setValue={setValue}
                    onChange={e => {
                      setValue('CarNumber', e.target.value)
                    }}
                    placeholder={'차량번호'}
                    

                  />
                  {/* <Button
                    type={'button'}
                    styleType={'tertiary'}
                    classes={'button-small'}
                    onClick={(e) => {

                      BtnLoadInfo('CarInfo')
                    }}
                  >
                    불러오기
                  </Button> */}
                </div>
                <Input
                  type={'text'}
                  required
                  {...register('CarModel')}
                  error={errors.CarModel}
                  setValue={setValue}
                  onChange={e => {
                    setValue('CarModel', e.target.value)
                  }}
                  placeholder={'차량모델명'}
                  

                />
              </div>


            </ReservationItem>

            {/** 이용차량 **/}
            <ReservationItemWithCheckbox title={'이용연장'} checkboxText={'있음'} onCheckChange={checkChange2}  >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                <div>
                  <Icon icon={'bul_exclamation'} style={{ width: '15px', height: '15px' }} />
                </div>
                <div>
                  <span style={{ fontSize: '12px' }}>
                    서비스 이용연장은 최대 2시간 까지 가능합니다.
                  </span>
                </div>
              </div>


            </ReservationItemWithCheckbox>

            <ReservationItem title={'요청사항'} >
              <div className={classNames('address-input')}>
                <div className={'input'}>
                  <Input
                    type={'text'}
                    required
                    {...register('CustomerComment')}
                    // error={errors.CarModel}
                    setValue={setValue}
                    onChange={e => {
                      setValue('CustomerComment', e.target.value)
                    }}
                    placeholder={'ex) 105동 지하 2층에서 만나요'}
                  />
                </div>
              </div>
            </ReservationItem>




            {isPC && (
              <>
                {usageCheck ? (<Button styleType={'primary'} disabled>
                  이용일 시점에 예약 가능합니다.
                </Button>) : (<Button styleType={'secondary'} type={'submit'}>
                  예약접수
                </Button>)}
              </>

            )}
          </ReservationStepContent>
        )}
        {!isPC && (
          <div className={classNames('mobile-button-set')}>
            {isChkPartnerLogin() ? 
            <>
            {step === 0 && (
              <Button
                styleType={'secondary'}
                onClick={setSelectedCustomer}
              >불러오기</Button>
            )}
            </> : 
            <>
            {step === 0 && (
              <Button
                styleType={'secondary'}
                onClick={setJasminCustomerStep0}
              >다음</Button>
            )}
            </>}

            {step === 1 && (
              <>
                {isChkPartnerLogin() ? (
                  <>
                    <div className={classNames('button-set')}>
                      <Button
                        onClick={() => setStep(0)}
                      >이전</Button>

                      <Button
                        styleType='secondary'
                        onClick={() => {
                          if (reservationDate.length <= 0) {
                            alert('예약일자를 하나 이상 선택해주세요')
                            return;
                          }
                          setStep(2)
                        }}
                      >다음</Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classNames('button-set')}>
                      <Button
                        onClick={() => setStep(0)}
                      >이전</Button>

                      <Button
                        styleType={'secondary'}
                        onClick={() => {
                          if (reservationDate.length <= 0) {
                            alert('예약일자를 하나 이상 선택해주세요')
                            return;
                          }
                          setStep(2)
                        }}
                      >다음</Button>
                    </div>
                  </>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ padding: '10px 0px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>선택한 예약일 </div>
                      <div><Button styleType='icon' onClick={() => { setReserveDateDetailFlag(!reserveDateDetailFlag) }}>{reserveDateDetailFlag ? '닫기' : '열기'}</Button></div>
                    </div>

                    {reserveDateDetailFlag && (
                      <>
                        {reservationDate.map((data) => {
                          return (<div style={{ padding: '5px 0px' }}>
                            {moment(data).format('yyyy-MM-DD')}<br />
                          </div>)
                        })}
                      </>
                    )}

                  </div>
                  <div className={classNames('button-set')}>
                    <Button
                      onClick={() => setStep(1)}
                    >이전</Button>

                    {usageCheck ? (<Button styleType={'primary'} disabled>
                      이용일 시점에 예약 가능합니다.
                    </Button>) : (<Button styleType={'secondary'} type={'submit'}>
                      예약접수
                    </Button>)}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </form >
      {/* {(firstVisit && isModalOpen) && <Modal
        title={'월간모시러 첫 이용을 환영합니다.'}
        subTitle={'첫 이용을 위해 마이페이지 주소 정보를 업데이트 해주세요.'}
      >
        <div className={classNames('button-set')}>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            닫기
          </Button>
          <Button
            styleType={'secondary'}
            onClick={() => {
              navigate(URLS.RESERVATION_MY_PAGE)
            }}
          >
            마이페이지로
          </Button>
        </div>
      </Modal>} */}
      {
        (completedModal && isModalOpen) && (
          <Modal
            title={'예약 접수 요청이 완료되었습니다.'}
            subtitle={'예약 접수에는 시간이 소요되므로 순차적으로 예약 접수처리 됩니다.'}
          >
            <div className={classNames('button-set')}>
              {isChkPartnerLogin() ? (
                <Button
                  styleType={'secondary'}
                  onClick={async () => {
                    await updateCustomerInfo()
                    setStep(1)
                    closeModal()
                    setCompletedModal(false);
                  }}
                >
                  확인
                </Button>
              ) : (
                <Button
                  styleType={'secondary'}
                  onClick={() => {
                    alert('완료 이후 Jasmin측 확인 필요')
                  }}
                >
                  완료
                </Button>
              )}

            </div>
          </Modal>
        )
      }
    </>

  )
}

export default CreateReservation



/* 

모시러쪽에서 부여할 수 있도록 한다.
1) 누구에게 초대할 것인지 선택한다. (부커/직원, 파트너)
2) 초대를 누르면 파트너에게 초대 메일 발송된다.
3) 파트너는 초대 메일의 링크를 클릭한다.
4) 회원가입창이 표시된다.
5) 파트너 이메일 아이디, 전화번호를 입력하여 인증받는다. (인증은 파트너 DB의 정보와 일치하는지를 체크한다.)  - AccountType이 email, apple, kakao일 경우는 고객, partner는 파트너를 의미. 
6) 인증이 완료되었다면 회원가입을 누른다. (회원가입을 누르면 파트너 정보를 고객 테이블에 저장하는 처리를 실시)
회원가입시 아래 정보만을 저장한다.
1. CustomerNo, 
2. UserId
3. AccountType
4. ParentCusotmerNo
7) 가입처리가 완료된다. 
8) 고객측에서는 리스트에 파트너 이름이 추가된다.
9) 파트너는 이메일 ID, Password를 입력한다. (로그인할때 AccountType이 partner라면 )


코엑스 현대백화점 10시 목요일
삼성역 

*/