import CryptoJS from 'crypto-js'
import axios from 'axios';
import qs from 'qs'
import moment from 'moment-timezone';

export const getCookieInfo = () => {
    const CookieData2 = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    return CookieData2
}

export const EncryptAES = (message1) => {
    const key = CryptoJS.enc.Utf8.parse('mosilerisbestexi');
    const iv = CryptoJS.enc.Utf8.parse('exitexitexitexit');

    let encrypted1 = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message1), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

    return encrypted1.toString()
}


export const DecryptAES = (encryptedMessage) => {
    const key = CryptoJS.enc.Utf8.parse('mosilerisbestexi');
    const iv = CryptoJS.enc.Utf8.parse('exitexitexitexit');

    let decrypted = CryptoJS.AES.decrypt(encryptedMessage, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
}


export const isChkResultSuccess = (response) => {
    if (response && response.Result === 'success') {
        //console.log("Result", "success")
        return true;
    }
    else
        return false;
}

export const getMasterAccountCustomerNo = (arr) => {
    let CustomerNo = 0;
    let cookieData = getCookieInfo();

    arr = JSON.parse(DecryptAES(cookieData?.lgupi))

    try {
        CustomerNo = arr.find((element) => element.MemberType === 'MASTER').CustomerNo
    }
    catch (e) {
        CustomerNo = 0
    }
    return CustomerNo
}

export const getLoginAccountInfoFromCookie = () => {
    let info = {};
    let cookieData = getCookieInfo();

    try {
        info = JSON.parse(DecryptAES(cookieData?.lgi))
    }
    catch (e) {

    }
    return info
}

export const getMasterAccountEmail = (arr) => {
    let UserId = '';
    let cookieData = getCookieInfo();

    arr = JSON.parse(DecryptAES(cookieData?.lgupi))
    try {
        UserId = arr.find((element) => element.MemberType === 'MASTER').UserId
    }
    catch (e) {
        UserId = ''
    }
    return UserId
}


export const getMasterAccountCustomerNoDd = (arr) => {
    let CustomerNo = 0;
    let cookieData = getCookieInfo();

    arr = JSON.parse(DecryptAES(cookieData?.lgupi_dd))

    try {
        CustomerNo = arr.find((element) => element.MemberType === 'MASTER').CustomerNo
    }
    catch (e) {
        CustomerNo = 0
    }
    return CustomerNo
}

export const getMasterAccountEmailDd = (arr) => {
    let UserId = '';
    let cookieData = getCookieInfo();

    arr = JSON.parse(DecryptAES(cookieData?.lgupi_dd))
    try {
        UserId = arr.find((element) => element.MemberType === 'MASTER').UserId
    }
    catch (e) {
        UserId = ''
    }
    return UserId
}




export const getEnglishDayToKorean = (daysName) => {
    let retData = ''
    switch (daysName) {
        case 'Mon':
            retData = '월'
            break;
        case 'Tue':
            retData = '화'
            break;
        case 'Wed':
            retData = '수'
            break;
        case 'Thu':
            retData = '목'
            break;
        case 'Fri':
            retData = '금'
            break;
        case 'Sat':
            retData = '토'
            break;
        case 'Sun':
            retData = '일'
            break;
        default:
            retData = '0'
            break;
    }
    return retData;
}

export const getAddressSigungu = (Address = '') => {
    let tmpAddr = Address?.split(' ')
    let retData = '';
    if (tmpAddr?.length >= 2) {
        retData = `${tmpAddr[0]} ${tmpAddr[1]}`
    }
    else {
        retData = '없음'
    }
    return retData;
}

export const isChkEmail = (data) => {
    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    return exptext.test(data);
}
export const isChkPw = (data) => {
    let exptext = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,10}$/;
    return exptext.test(data);
}

export const getQueryStringObject = () => {
    var a = window.location.search.substr(1).split('&');
    if (a === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length === 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
}

export const getEstimateCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    return CookieData.estimateinfo
}

export const isChkEstimateCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = false;
    try {
        if (CookieData?.estimateinfo?.length !== undefined) {
            retData = true;
        }
    }
    catch (e) { }
    return retData;
}

export const isChkDdEstimateCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = false;
    try {
        if (CookieData?.estimateddinfo?.length !== undefined) {
            retData = true;
        }
    }
    catch (e) { }
    return retData;
}

export const isChkReservationCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = false;
    try {

        if (CookieData?.lgupi?.length !== undefined) {
            retData = true;
        }
    }
    catch (e) {
        retData = true;
    }
    return retData;
}

export const isChkDriverManageCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = false;
    try {

        if (CookieData?.lgupi_dd?.length !== undefined) {
            retData = true;
        }
    }
    catch (e) {
        retData = true;
    }
    return retData;
}

export const isChkDdManageCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = false;
    try {

        if (CookieData?.lgupi_dd?.length !== undefined) {
            retData = true;
        }
    }
    catch (e) {
        retData = true;
    }
    return retData;
}

export const getDriverDispatchLoginInfoCookieData = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});


    let retData = {};
    try {
        if (CookieData?.lgi_dd?.length !== undefined) {
            retData = JSON.parse(DecryptAES(CookieData?.lgi_dd))
        }
    }
    catch (e) {

    }
    return retData;
}


export const getEstimateCookieDataAndDecrypt = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = {};
    try {
        retData = JSON.parse(decodeURIComponent(atob(CookieData.estimateinfo)))
    }
    catch (e) {

    }
    return retData;
}

export const getDdEstimateCookieDataAndDecrypt = () => {
    const CookieData = document.cookie
        .split(';')
        .reduce((res, c) => {
            const [key, val] = c.trim().split('=').map(decodeURIComponent)
            try {
                return Object.assign(res, { [key]: JSON.parse(val) })
            } catch (e) {
                return Object.assign(res, { [key]: val })
            }
        }, {});
    let retData = {};
    try {
        retData = JSON.parse(decodeURIComponent(atob(CookieData.estimateddinfo)))
    }
    catch (e) {

    }
    return retData;

}



export const getDateCompateToString = (targetDate) => {
    // 현재 날짜를 설정합니다. 여기서는 2023-09-16으로 고정합니다.
    const currentDate = moment();

    // 목표 날짜를 설정합니다. 인자로 들어온 날짜를 사용합니다.
    const target = moment(targetDate);

    // 두 날짜의 차이를 일(day) 단위로 계산합니다.
    const diffInDays = target.diff(currentDate, 'days');

    let retData = ''

    // 조건에 따라 문자열을 반환합니다.
    if (diffInDays >= 0 && diffInDays <= 15) {
        retData = "가능하다면 빠르게";
    } else if (diffInDays > 15 && diffInDays <= 30) {
        retData = "1개월 이내";
    } else if (diffInDays > 30 && diffInDays <= 60) {
        retData = "2개월 이내";
    } else {
        retData = "날짜 확인 필요";
    }
    retData += `(${target.format('yyyy-MM-DD')}(${getEnglishDayToKorean(target.format('ddd'))}))`
    return retData;
}


// true : 출발지, 종료지 서울임
// false : 출발지 종료지 둘중 하나는 서울이 아님
export const isChkAddressSeoul = (StartAddress = '') => {
    return (StartAddress.indexOf('서울') >= 0)
}

export const sleep = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, time);
    })
}

export const getHourMinute = (allminute) => {
    return `${Math.floor(allminute / 60)}시간 ${allminute % 60}분`
}

// parameter type : date, int
// return type : moment().toDate() or new Date()
export const getReserveAvaliableDate = (serverDate, closeHour) => {
    /*
        기준시간 : 6/24. 
            19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.
            19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가.

        시간쪽도 disabled 제한 해제가 필요하고, 0~23시까지 선택 가능하도록 해야할듯.

        화면을 열었을때 시간체크 1회 실시.
        다음버튼 클릭시 시간체크 1회 실시.
            => 19시가 넘었을때 시간 처리를 실시해야한다.
    */
    let tmpMoment = moment(serverDate).tz('Asia/Seoul');
    let retDate = null;

    // 현재 시간이 closeHour보다 크거나 같을 때
    if (tmpMoment.hours() >= closeHour) {
        // 19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가. 다다음날 예약 가능.
        retDate = tmpMoment.add(2, 'days').startOf('day');
    }
    else {
        // 19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.
        retDate = tmpMoment.add(1, 'days').startOf('day');
    }
    // console.log('date: ', retDate.toDate())
    return retDate.toDate();
}

// 금일 선택 불가, 19시 이후 금일/명일 선택 불가, 그외의 날짜를 파악하여
// 해당되는 flag를 리턴하는 메소드다.
export const getSelectedDateAlertFlag = (serverDate, selectedDate, closeHour) => {
    /*
        기준시간 : 6/24. 
            19시 이전 : 6/24 선택 불가, 6/25 선택 가능 => 당일 예약 불가. 다음날 예약 가능.                     flag : 1
            19시 이후 : 6/24 선택 불가, 6/25 선택 불가, 6/26 선택 가능 => 당일 예약 불가. 다음날 예약 불가.       flag : 2
            상기 이외 : 해당없음.                                                                      flag : 3
    */
    let tmpMoment = moment(serverDate).tz('Asia/Seoul');
    let retData = 0;

    let nowTimeStartOf = moment(serverDate).tz('Asia/Seoul').startOf('day')         // 현재 서버상의 시간과
    let selDateStartOf = moment(selectedDate).tz('Asia/Seoul').startOf('day')       // 내가 선택한 날짜를 
    let diffDays = moment.duration(selDateStartOf.diff(nowTimeStartOf)).asDays(); // 일자를 비교한다.

    // 19시 이후 케이스이다.
    if (tmpMoment.hours() >= closeHour) {
        if (diffDays === 0) {       // 금일 선택시
            retData = 1;
        }
        else if (diffDays === 1) {   // 명일 선택시
            retData = 2;
        }
        else {                      // 그 이외의 날짜 선택시
            retData = 3;
        }
    }

    // 19시 이전 케이스
    else {
        if (diffDays === 0) {       // 금일 선택시
            retData = 1;
        }
        else {                      // 금일 이외 선택시
            retData = 3;
        }
    }
    return retData;
}



export const isDev = () => {
    return (Number(process.env.REACT_APP_IS_DEV) === 1)
}



/*
[IN]
let reservelist = [
{
    ...
    "RevStartAddress": "서울 서대문구 증가로2길 40-16 연희궁아파트",
    "RevStartLat": "37.57325447926784",
    "RevStartLng": "126.92962111377638",
    "RevWaypointAddress": "서울 홍대입구역",
    "RevWaypointLat": "37.5568707448873",
    "RevWaypointLng": "126.923778562273",
    "RevEndAddress": "서울특별시 서대문구 증가로2길 40-16 연희궁아파트",
    "RevEndLat": "37.57325447926784",
    "RevEndLng": "126.92962111377638"
},

[OUT]
[{
  Address: "서울 서대문구 증가로2길 40-16 연희궁아파트",
  Lat: "37.57325447926784",
  Lng: "126.92962111377638"
}, {
  Address: "서울 홍대입구역",
  Lat: "37.5568707448873",
  Lng: "126.923778562273"
}, {
  Address: "서울특별시 서대문구 증가로2길 40-16 연희궁아파트",
  Lat: "37.57325447926784",
  Lng: "126.92962111377638"
}]
*/
export const getAddressDeduplication = (reservelist) => {
    let uniqueAddresses = {};

    reservelist.forEach(item => {
        // 시작 주소 중복 제거
        if (item.RevStartAddress && !uniqueAddresses[item.RevStartAddress]) {
            uniqueAddresses[item.RevStartAddress] = {
                Address: item.RevStartAddress,
                Lat: item.RevStartLat,
                Lng: item.RevStartLng
            };
        }

        // 중간 주소(waypoint) 중복 제거
        if (item.RevWaypointAddress && !uniqueAddresses[item.RevWaypointAddress]) {
            uniqueAddresses[item.RevWaypointAddress] = {
                Address: item.RevWaypointAddress,
                Lat: item.RevWaypointLat,
                Lng: item.RevWaypointLng
            };
        }

        // 종료 주소 중복 제거
        if (item.RevEndAddress && !uniqueAddresses[item.RevEndAddress]) {
            uniqueAddresses[item.RevEndAddress] = {
                Address: item.RevEndAddress,
                Lat: item.RevEndLat,
                Lng: item.RevEndLng
            };
        }
    });

    // 결과를 배열로 변환
    return Object.values(uniqueAddresses)

}
export const isChkCarNumber = (str) => {
    let retData = false;
    if (/^\d{2}[가-힣]\d{4}/.exec(str) !== null && str.length === 7) {
        retData = true
    }
    else if (/^\d{3}[가-힣]\d{4}/.exec(str) !== null && str.length === 8) {
        retData = true
    }
    else {
        retData = false;
    }
    return retData
}

// listData : map을 통해 순회하면서 데이터 안의 계정 정보
export const isChkAccount = (accountInfo) => {
    /*
    1) 마스터 계정일 경우
        - true 리턴
    
    */
    let retData = false;
    try {
        let cookieInfo = getCookieInfo()
        let lgupi = JSON.parse(DecryptAES(cookieInfo?.lgupi))
        let lgi = JSON.parse(DecryptAES(cookieInfo?.lgi))
        // 현재 로그인한 사람이 Master인지 Sub인지 

        if (lgupi.find((element) => element.UserId === lgi.UserId).MemberType === 'SUB') {
            if (accountInfo.UserId === lgi.UserId) {
                retData = true;
            }
        }
        else {
            retData = true;
        }
        //CustomerNo = 

    }
    catch (e) {

    }

    return retData;
}


export const isMasterAccountLoggedIn = () => {
    let retData = false;
    try {
        let cookieInfo = getCookieInfo()
        let lgupi = JSON.parse(DecryptAES(cookieInfo?.lgupi))
        let lgi = JSON.parse(DecryptAES(cookieInfo?.lgi))
        // 현재 로그인한 사람이 Master인지 Sub인지 

        if (lgupi.find((element) => element.UserId === lgi.UserId).MemberType === 'MASTER') {
            retData = true;
        }
    }
    catch (e) {

    }

    return retData;
}

export const getTotalMinuteToHourMinuteStr = (totalminute) => {
    let retData = '';
    if (Math.floor(Number(totalminute) % 60) === 0) {
        retData = `${Math.floor(Number(totalminute) / 60)}시간`
    }
    else {
        retData = `${Math.floor(Number(totalminute) / 60)}시간 ${Math.floor(Number(totalminute) % 60)}분`
    }
    return retData
}

export const getTotalMinuteToHourMinuteStr2 = (totalminute) => {
    let retData = '';
    retData = `${Math.floor(Number(totalminute) / 60)}시간 ${Math.floor(Number(totalminute) % 60)}분`
    return retData
}

export const getBusinessCodeFormat = (number) => {
    return number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
}

export const getNumber2Ciphers = (data) => {
    let intData = parseInt(data);
    let retData = ''

    if (intData >= 0 && intData <= 9) {
        retData = `0${intData}`
    } else {
        retData = `${data}`
    }
    return retData;
}




export const base64EncodeUnicode = (str) => {
    let bytes = new (typeof TextEncoder === "undefined" ? TextEncoder : TextEncoder)("utf-8").encode(str);
    let base64encoded = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let padding = bytes.length % 3;
    for (let i = 0; i < bytes.length; i += 3) {
        let a = bytes[i];
        let b = bytes[i + 1] || 0;
        let c = bytes[i + 2] || 0;
        base64encoded += characters[a >> 2] + characters[((a & 3) << 4) | (b >> 4)] + (typeof bytes[i + 1] !== "undefined" ? characters[((b & 15) << 2) | (c >> 6)] : '=') + (typeof bytes[i + 2] !== "undefined" ? characters[c & 63] : '=');
    }
    return base64encoded;
}

export const camelToKebab = (string) => {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}

export const getKeyByIndex = (targetArr, targetIndex) => {
    for (let key in targetArr) {
        if (targetArr[key].index === targetIndex) {
            return camelToKebab(key);
        }
    }
    return null; // 일치하는 index가 없을 경우 null 반환
}


export const removeHyphen = (phone) => {
    return phone.replace(/-/g, '');

}



export const isTimeOverlapping = (refArr, startDt, endDt) => {
    for (let i = 0; i < refArr.length; i++) {
        let refStart = moment(refArr[i]?.RevStartDt);
        let refEnd = moment(refArr[i]?.RevEndDt);

        if (startDt.isBefore(refEnd) && endDt.isAfter(refStart)) {
            return true; // 겹치는 시간이 있다면 true를 반환
        }
    }
    return false; // 겹치는 시간이 없다면 false를 반환
}

export const isChk2023Chusok = (nowDate, hour) => {
 
    /*
    현재 날짜가
    2023-09-27 15:00 부터 2023-10-03 14:59:59까지일 경우
    => moment('2023-10-04T00:00:00').toDate()를 리턴
    2023-10-03 15:00:00 이후부터는 기존 프로세스대로 진행
    => 2023-10-05 예약이 풀려야함
    moment('2023-10-05T00:00:00').toDate()를 리턴
    */
    const currentDate = moment(nowDate).tz('Asia/Seoul');

    const startDate = moment(`2023-12-08T${hour}:00:00`);
    const endDate = moment(`2023-12-11T${hour}:00:00`);

    let resultDate;

    // 현재 날짜와 시간이 설정한 날짜 범위 내에 있는지 확인
    if (currentDate.isBetween(startDate, endDate, 'second', '[]')) {
        // 범위 내에 있다면, 2023-10-04T00:00:00를 반환
        resultDate = moment('2023-12-12T00:00:00').toDate();
    } else {
        // 범위 이후라면, 2023-10-05T00:00:00를 반환
        resultDate = getReserveAvaliableDate(nowDate, hour)
    }

    return resultDate

};