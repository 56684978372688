import React, { useState, useEffect } from 'react';
import { URLS } from '../../../../utils/urls';

import AccountApi from '../../../../lib/AccountApi'
import { isChkResultSuccess, sleep, EncryptAES } from '../../../../lib/Util';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bookerPageLoginUser, bookerPageLoginUserPool } from '../../../../reducers/Users';
import { useCookies } from 'react-cookie'

import { openLoading, closeLoading } from '../../../../reducers/loadingRedux';


/**
 * 로그인
 * path: reservation/login/email-login
 * **/


const EmailLogin = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies();

	useEffect(() => {
		async function loginCheck() {
			try {
				dispatch(openLoading())
				// 로그인
				let result = await AccountApi.JasminLogin()
				if (isChkResultSuccess(result)) {

					dispatch(bookerPageLoginUser(result.Item))
					dispatch(bookerPageLoginUserPool(result.ItemList))

					const expires = new Date()
					expires.setDate(expires.getDate() + 90)

					setCookie('li_01', result.infoData, { path: '/', expires })
					setCookie('li_02', result.Item.LoginEncrypt, { path: '/', expires })
					setCookie('alchk', 1, { path: '/', expires })
					setCookie('lgi', EncryptAES(JSON.stringify(result.Item)), { path: '/', expires })
					setCookie('lgupi', EncryptAES(JSON.stringify(result.ItemList)), { path: '/', expires })

					await sleep(500)
					navigate(URLS.RESERVATION_CREATE)

					//CookieApi.SettingCookie(setCookie, result.infoData, result.Item.LoginEncrypt, getValues('autoLogin'))
					// ID, PW 암호화 데이터 쿠키에 저장
					// li_01 : ID, PW
					// li_02 : LoginEncrypt
					// 쿠키에 로그인, API 호출 키 관련 정보 암호화
					// Redux에 데이터 넣는것
					// rise1234test@mosiler.com // 1234
				}
				else {
					alert(result.Message)
				}
				dispatch(closeLoading())
			}
			catch (e) {

			}



		}
		loginCheck()
	}, [])



	return (<></>)
};

export default EmailLogin